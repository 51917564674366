import { pathConfig } from "./../../../utils/interface";

/**
 * @description Configuracion sidebar
 * En este documento se oueden configurar las rutas que aparecen en el sidebar y a que ligar auntan,
 * pero en el caos que se decee alterar un ade estas, se debe realizar el cambio en este lugar, a la vez en el 
 * documento src/Views/Navigation.tsx ya que en este se crean las rutas.
 * mas especificamente en la funcion que las crea que es la funcion Views.
 */
export const paths:Array<pathConfig> = [
    {
        name:"viajes",
        icon:"icon-tabla-archivo",
        path:"/home"
    },
    {
        name:"Historial",
        icon:"icon-history",
        path:"/home/history"
    }
];