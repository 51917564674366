import React from "react";
import Icon from "./../Icon/Icon.tooltip";
import Button from "./../Button/Button";
import "./Image.scss";


interface propsDownload {
    /**
     * la uri que se pasa por aqui es una uri local, por lo que se pasa un string de URL.createObjectUrl
     */
    uri:string;
    name?:string;
}

/**
 * @description permite descargar imagenes 
 * @version 1.0
 * @todo falta mejorar la descarga de la imagen 
 */
export const DownloadImage = (props:propsDownload) => {

    /**Refrencia de la etiqueta <a/>*/
    const el = React.useRef(null);

    /** Maneja el evento click en el componente */
    const _onClick = () => {
        let _el:any = el.current;
        if(_el?.click) _el.click();
    };

    return (
        <div className="download-container">
            <a download={`${new Date().getTime()}`} href={props.uri} ref={el} className="download-link"/>
            <Button onClick={_onClick} className="download-button">
                <Icon className="icon-descargar" message="Descargar image"/>
            </Button>
        </div>
    );
};