import React from "react";
import Button from "../../Basic/Button/Button";
import Icon from "../../Basic/Icon/Icon.tooltip";
import "./Utils-view.scss";
import { objectKV, excelField } from "../../../utils/interface";
import usePortal from "../../hook/portal-hook";
import ReactDOM from "react-dom";
import { ExcelSheetDownload } from "../Excel/Excel-download";
import { useLazyQueryToSever } from "../../hook/query-hook";
import { destructor } from "../../../utils/utils";


interface propsFilterOption {
    /**
     * este parametro dice si los filtros esta ocultos o visibles ne le momento
     */
    hidden:boolean;
    /**
     * Este es el llamado a la funcion que resetea los filtros
     */
    reset:()=>any;
    /**
     * este es el llamdo a la funcion que oculta los filtros o los muestra 
     */
    hiddenFilters:(v:boolean) => any;
    /**
     * Esta es la data que se mostrarar en el pdf
     */
    data?:Array<objectKV>;
    /**
     * Estos son los campos de configuracion del doc de excel
     */
    execlFields?:Array<excelField>;
    /**
     * NOmbre del doc que se convertira a excel
     */
    nameDoc?:string;
    /**
     * Este es el query que pedira todos los datos la base de datos cuanso se decea exportar todo no solo lo selecionado 
     */
    query?:string;
    /**
     * Son las posibles variables que tendra el query
     */
    variables?:objectKV;
    /**
     * es la ruta de la que se estraera la informacion para renderizara
     */
    path?:string;
    /**
     * son los filtros actuales.
     */ 
    filters?:objectKV;
}

export const OptionsDownload = (props:{data:Array<objectKV>,path:string, query:string,variables?:objectKV, fields:Array<excelField>, name:string}) => {

    const el = usePortal("select-root");

    const [visible, setVisible] = React.useState<boolean>(false);

    const _onMouseMoveCapture = () => {
        setVisible(true);
    };

    const _onMouseLeave = () => {
        setVisible(false);
    };

    return (<Button onMouseMoveCapture={_onMouseMoveCapture} onMouseOutCapture={_onMouseLeave} className="button download-option-button">
        {ReactDOM.createPortal(
            <div onMouseMoveCapture={_onMouseMoveCapture} onMouseOutCapture={_onMouseLeave} className="modal-options-download" style={{width:visible?"":0}}>
                <ExportExcel path={props.path} data={props.data} query={props.query} variables={props.variables} name={props.name} fields={props.fields}/>
                {/* <Button className="button-download">
                    <Icon className="icon-PDF" message="Descargar pdf"/>
                    <PdfDownload />
                </Button> */}
            </div>
        ,el)}
        <Icon className="icon-descargar"/>
        </Button>
    );
};

export const ExportExcel = (props:{classNameBtn?:any;fields:Array<excelField>,path:string, data:Array<objectKV>, query:string,variables?:objectKV, name:string}) => {

    const elExcelDownload = React.useRef(null);
    const [data, setData] = React.useState<Array<objectKV>>();
    const [loadData, {data:dataFetch,called, loading, error,refetch}] = useLazyQueryToSever({query:props.query, fetchPolicy:"cache-first"});

    const assingData = (v:objectKV) => {
        //Nota dej path en este punto porque se supone no debe cambiar en caso de que sea algo que cambia se debe definir de otra manera 
        const _data = destructor(props.path, v);
            if(_data?.length){
               setData(_data);
            }
    };

    React.useEffect(() => {
        if(data?.length){
            let _elExcelDownload:any = elExcelDownload.current;
            if(_elExcelDownload){
                _elExcelDownload.download(props.name);
                setData(undefined);
            }
        }
    },[data]);

    React.useEffect(() => {
        if(dataFetch && !loading) assingData(dataFetch);
    },[dataFetch,loading]);

    const _onClickExcelDownload = () => {
        if(props?.data?.length){
            setData(props.data);
        } else {
            if(called && refetch) refetch().then((res:objectKV) => {
                if(!res?.loading && res?.data) assingData(res.data);
            });
            else loadData({variables:props.variables});
        }
    };

    return (
        <React.Fragment>
            <ExcelSheetDownload data={data} fields={props.fields} ref={elExcelDownload} />
        <Button onClick={_onClickExcelDownload} className={"button-download "+(props.classNameBtn??"")}>
            <Icon className="icon-Excel" message="Descargar excel"/>
        </Button>

        </React.Fragment>
    );
};

export const FiltersOptions = (props:propsFilterOption) => {

    const [isFiltered, setIsFiltered] = React.useState<boolean>(false);

    React.useEffect(() => {
        let filters = Object.values(props.filters ?? {});
        setIsFiltered(!!filters.filter(Boolean).length)
    },[props.filters]);

    return (
        <div className="filters-options">
            <div className="filters-options__container">
                <Button onClick={() => props.hiddenFilters(!props.hidden)} className="button" style={{color:isFiltered?"#2192df":""}}>
                    <Icon className="icon-filter" message={props.hidden? "Mostrar filtros": "Ocultar filtros"}/>
                    <Icon className={props.hidden? "icon-eye": "icon-hidden"} message={props.hidden? "Mostrar filtros": "Ocultar filtros"}/>
                </Button>
                <Button onClick={props.reset} className="button">
                    <Icon className="icon-filter" message="Limpiar"/>
                    <Icon className="icon-slash" message="Limpiar"/>
                </Button>
               {props.path && props.query && props.execlFields && props.data && props.nameDoc ?<OptionsDownload path={props.path} query={props.query} variables={props.variables} data={props.data} fields={props.execlFields} name={props.nameDoc} />:null}
            </div>
        </div>
    );
};

export const TotalRegs = React.memo((props:{text?:string}) => {
return (<div className="total-regs">{props.text??""}</div>);
});

/**
 * @description creamos la estructura de los filtros, ya que el state almacena valores diferentes, es como sanitizar
 * @param v 
 */
export const createFilters = (v:objectKV) => {
    let filters = Object.keys(v).map((item:string, index:number) => {
        let value = v[item];
        if(!value) return false;
        return {field: item,value}
    })
    return filters.filter(Boolean);
};

/**
 * @description transforma los valores del state, en valores que reciba graphql 
 * @param v 
 */
export const parceOrders = (v:objectKV) => {
    if(!v) return;
    let order = {field: v.field,type:v.order}
    return order;
};
