import React from "react";
import { objectKV, excelField } from "../../../utils/interface";
import { destructor } from "../../../utils/utils";

interface propsDownload {
    fields:Array<excelField>;
    data?:Array<objectKV>;
}

export const ExcelSheetDownload = React.memo(React.forwardRef((props:propsDownload,ref:any) => {
    const elDownload = React.useRef(null);
    const elTable = React.useRef(null);

    const base64 = (s:any) =>  {
        return window.btoa(unescape(encodeURIComponent(s)))
    };

    const download = (name:string = "doc_excel") => {
        let uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
        , base64 = (s:string | number | boolean) => { return window.btoa(unescape(encodeURIComponent(s))) }
        , format = function(s:string, c:objectKV) { return s.replace(/{(\w+)}/g, (m, p) => { return c[p]; }) }
        const _elDownload:any = elDownload.current;
        const _elTable: any = elTable.current;
        if(_elDownload && _elTable) {
            const ctx = {worksheet: name || 'Worksheet', table: _elTable.outerHTML}
            _elDownload.href = uri + base64(format(template, ctx));
            _elDownload.download = `${name}.xls`; 
            _elDownload.click();

        }
    };

        /**
     * Crea los funciones que se ejecutaran por referencia.
     */
    React.useImperativeHandle(ref, ()=>({
        download
    }));

    return (
        <React.Fragment>
            <a type={"hidden"} ref={elDownload} download />
            <div style={{height:1, width:1, overflow:"hidden"}}>
            <table ref={elTable}>
                <thead>
                    <tr>
                        {
                            props.fields.map((item:excelField,index:number) => <th key={item.id ?? index} >{item.value}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data?.length? props.data.map((data:objectKV, index:number) => <tr key={data.id ?? index}>{
                                props.fields.map((item:excelField,index:number) => <th key={item.id ?? index} >{
                                    item.path?destructor(item.path, data): typeof(item.getData) === "function"? item.getData(data): "NA"
                                }</th>)
                            }</tr>
                        ):null
                    }
                </tbody>
            </table>
            </div>
            
        </React.Fragment>
    );
}));