import React from "react";
import { action, objectKV, error } from "../../../utils/interface";

export interface documentInterface {
    document?:string;
    //NOTA no pase un id la primera vez que lo agrega (podria generar errores de sobre escritura) pase id solo para editar
    id?:number | string;
    min?:string;
    visible?:boolean;
}

const GalleryContext = React.createContext(undefined);

export const GalleryProvider = (props:any) => {

    const reducer = (state:any, action:action) => {
        switch(action.type) {
            case "SET_IMAGE":
                if(state.images?.length && state.images?.find((item:documentInterface) => item.id === action?.value?.id)?.id) {
                    let images = [...state.images.map((item:documentInterface, index:number) => {
                        if(item.id === action.value?.id){
                            return Object.assign({}, item, action?.value);
                        }
                        return item;
                    })]
                    return Object.assign({}, state, {images});
                }
                let ids =  state?.images?.length ? state?.images?.map((item:documentInterface) => +(item.id ?? 0)): [];
                let lastId = Math.max(0,...ids);
                // let tempImagesState = (state?.images ?? []);
                // tempImagesState = tempImagesState.length?tempImagesState.filter((item:any) => item?.document !== action.value?.document):[];
                let images = [...(state?.images ?? []), Object.assign({},action.value, {id:lastId + 1})];
                return Object.assign({}, state, {images});
            case "SET_VOID":
                return Object.assign({}, state, {images:[]});
            default: 
                throw new Error();
        }
    };

    let init = {
        images:[]
    }
    const [state , dispatch] = React.useReducer(reducer, {});

   
    let value = React.useMemo(()=>({state , setImage:dispatch}), [state , dispatch]);
    return <GalleryContext.Provider value={value} {...props} />
};

export const useGallery = ():{error?:error, state:objectKV, setImage:(v:action) => any} => {
    const context = React.useContext(GalleryContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};