import React from 'react';
import DayPicker, { DateUtils, RangeModifier } from 'react-day-picker';
import { error } from '../../../utils/interface';
import Button from '../Button/Button';
import { ClickAwayListener } from '../ClickAwayListener/Click.away-listener';
import 'react-day-picker/lib/style.css';
import "./Input.scss";
import { dateToString, getRangeDays, getRangeMonths, stringToDate } from '../../../utils/utils';

interface DateRangeProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    label?:string;
    /** */
    [v:string]:any;
}

/**
 * determina si los vales que se pasaron corresponden a una semana, mes, semestre.....
 * @param _date 
 * @returns 
 */
export const getItemSelectedRange = (_date:Array<string>) => {
    const getMounts = (v: number) => dateToString(getRangeMonths(v)).split("-").join("/");
    let today = dateToString(new Date()).split("-").join("/");
    if (_date[1] !== today || !_date[0] || !_date[1]) { }
    else if (_date[0] === today && (!_date[1] || _date[1] === today)) return `TODAY`;
    else if (_date[0] === dateToString(getRangeDays(7)).split("-").join("/")) return `WEEK`;
    else if (_date[0] === getMounts(1)) return `MONTH`;
    else if (_date[0] === getMounts(3)) return `3-MONTHS`;
    else if (_date[0] === getMounts(6)) return `6-MOUTHS`;
    else if (_date[0] === getMounts(12)) return `YEAR`;
    else { }
    return `OTHER`
};

const month = "(0?[2-9]|1[012]|01)";
const day = "(0?[4-9]|[12][0-9]|3[01]|01|02|03)";
const year = "(20[0-9]{2})";

/**
 * @description permite crear una expresion regular
 * @param v 
 * @returns 
 */
const createRegex = (v: string) => {
  let res = typeof (v) === "string" ? new RegExp(`^${v}$`) : new RegExp(`.`,'g');
  return res;
}

/**
 * @descripcion esta funcion permite renderizar el componente de input de fecha
 */
export const DateRange = React.memo((props:DateRangeProps) => {
  
  let { label, error, focused, className, style, name,onChange,value:valueProps, ...allRest } = props;
  /**Valor del campo */
  const [_value, setValue ] = React.useState<string>();
  /**Indica si el campo esta enfocado*/
  const [focus, setFocus] = React.useState<boolean>(false);
  /**Indica si las opciones son visbles */
  const [visible, setVisible] = React.useState<boolean>(false);

  /**Abre las opciones */
  const _open = React.useCallback(() => {
    if (allRest.disabled) return;
    setVisible(true);
  },[ allRest.disabled]);

  /**Cierra las opciones */
  const _close = React.useCallback(() => {
    setVisible(false);
  }, []);
  
  /**Cambia la bandera de enfoque a true */
  const _onFocus = () => {
      if(focused) focused(true);
    setFocus(true);
  };

  /**Cambia la bandera de enfoque a false */
  const _onBlur = () => {
      if(focused) focused(false);
      setFocus(false);
  };

  /**Reaciona la cambio en el compopnente input */
  const _onChange = React.useCallback((e: any) => {
    setValue((value: any) => {
      let tempValue = e?.target?.value ?? "";
      /**Valida si se esta borrando un valor */
      if (value?.length > tempValue?.length) {
        if (onChange ) onChange({ target: {name,value:tempValue}});
        return tempValue;
      }
      /**Valida el maximo nuemro de caracteres que tendra una fecha */
      if (tempValue?.length > 21) return value;

      /**Se verifica segun el nuemro de caracteres y la expresion regular si es momento de agregar un / o - */
      if ([1, 2].includes(tempValue.length) && tempValue.match(createRegex(day))) tempValue = tempValue + "/";
      else if ([3, 4, 5].includes(tempValue.length) && tempValue.match(createRegex(`${day}\/${month}`))) tempValue = tempValue + "/";
      else if ([5, 6, 7, 8, 9, 10].includes(tempValue.length) && tempValue.match(createRegex(`${day}\/${month}\/${year}`))) tempValue = tempValue + "-";
      else if ([10, 11, 12, 13].includes(tempValue.length) && tempValue.match(createRegex(`${day}\/${month}\/${year}-${day}`))) tempValue = tempValue + "/";
      else if ([12, 13, 14, 15, 16].includes(tempValue.length) && tempValue.match(createRegex(`${day}\/${month}\/${year}-${day}\/${month}`))) tempValue = tempValue + "/";
      else if ([14, 15, 16, 17, 18, 19, 20, 21].includes(tempValue.length) && tempValue.match(createRegex(`${day}\/${month}\/${year}-${day}\/${month}\/${year}`))) tempValue = tempValue;
      else {
        if (onChange ) onChange({ target: {name,value:tempValue}});
        return tempValue;
      }
      if (onChange ) onChange({ target: {name,value:tempValue}});
      return tempValue;
    });
  }, [name]);
  
  /**Reaciona al cambio del componente de Range */
  const _onChangeRange = React.useCallback((e: any) => {
    if (onChange ) onChange({ target: {name,value:e}});
    setValue(e);
  },[]);
  
  React.useEffect(() => {
    setValue((value: any) => {
      if (value === valueProps) return value;
      return valueProps;
    });
   },[valueProps]);

  return (
    <div className="container">
      {label ? <label className={`text-conf ${focus ? "label-focus" : ""} ${error?.error ? "error" : ""}`}>{label}</label> : null}
      <input
        onClick={ _open }
        onFocusCapture={_onFocus}
        onBlurCapture={_onBlur}
        autoComplete="off"
        value={_value}
        name={name}
        onChangeCapture={_onChange}
        className={`input ${className ?? ""}`}
        type="text"
        style={{...{borderColor:error?.error?"red":""},...style}}
        {...allRest}
        />
      {/* <div className="container-input-range-date">
        <div className="container-input-range-date__button-calendary">
          <Button className="container-input-range-date__button-calendary__button">
            <Icon message="Abrir opciones" className="icon-calendar" />
          </Button>
        </div>
      </div> */}
      {error?.error ? <label className="text-conf error">{error.message}</label> : null}
      <DateRangeModal onChange={_onChangeRange} value={ _value } visible={ visible } close={_close} open={ _open } />
    </div>
  );
});
 

interface DateRangeModal{
  visible: boolean;
  close?: (v: any) => any;
  open?: (v: any) => any;
  onChange?: (v:any) => any;
  value?: any;
}

/**Valor inicial del Range */
const initial = { from: null, to: null };

/** Este funciones se encarga de renderizar el compoenente que muestra las opciones*/
export const DateRangeModal = React.memo((props: DateRangeModal) => {

  const { visible, close, open,onChange,value:valueProps } = props;

  const [value, setValue] = React.useState<RangeModifier> (initial);
  
  const valueString = React.useMemo(() => {
    let _from = dateToString(value.from).split("-").join("/");
    let _to = dateToString(value.to).split("-").join("/");
    return `${_from}${_from && _to ? "-" : ""}${_to}`;
   },[value]);

  /** Reaciona al click del ok*/
  const onClickOK = React.useCallback(() => {
    /**Convertimos la fecha en un tipo de dato string valido */
    let temp = valueString;
    /**Cerramos la ventana */
    if (close) close(true);
    /**Salvamos los cambios */
    if (temp === valueProps || !onChange) return;
    onChange(temp);
  }, [valueString, valueProps, close]);
  
  /**Reaciona a los cambios en el value que viene en las props */
  React.useEffect(() => {
    /**Convertimos el string DD/MM/YYYY-DD/MM/YYYY en dos valores [DD/MM/YYYY,DD/MM/YYYY] */
    let temp = valueProps?.split("-") ?? [];
    /**Convertimos el valor en un Date */
    let _from = stringToDate(temp[0]?.split("/")?.join("-"));
    let _to = stringToDate(temp[1]?.split("/")?.join("-"));
    /**Salvamos el valor */
    setValue((v: RangeModifier) => {
      /**Validamos que por lo menos exista un cambio */
      if (dateToString(v.from) !== dateToString(_from) || dateToString(v.to) !== dateToString(_to)) return { from: _from, to: _to };
      return v;
    });
  },[valueProps]);

  /**Reaciona al cambio en el calendario */
  const dayClick = React.useCallback((day: any) => {
    /**Cramos un rango */
    const range = DateUtils.addDayToRange(day, value);
    /**Salvamos el rango */
    setValue(range);
  }, [value])

  /**Reaciona al click en el boton de reset,asignamos el valor inicial  */
  const reset = React.useCallback(() => {
    setValue(initial);
    // if (onChange) onChange("");
  }, [])
  
  /**Este es el valor que se muetra en el calendario */
  const modifiers: any = React.useMemo(() => {
    return {from:value.from,to:value.to};
  }, [value]);
  
  /**Reaciuona al click en hoy asignando el mismo dia */
  const today = React.useCallback(() => {
    setValue({from:new Date(),to:new Date()});
  }, []);
  
  /**Reaciuona al click en semana asignando 7 dias menos */
  const week = React.useCallback(() => {
    setValue({from:getRangeDays(7),to:new Date()});
  }, []);
  
  /**Reaciuona al click en mes asignando 1 mes menos */
  const month = React.useCallback(() => {
    setValue({from:getRangeMonths(1),to:new Date()});
  }, []);
  
  /**Reaciuona al click en trimestre asignando 3 meses menos */
  const month3 = React.useCallback(() => {
    setValue({from:getRangeMonths(3),to:new Date()});
  }, []);
  
  /**Reaciuona al click en semestre asignando 6 meses menos */
  const month6 = React.useCallback(() => {
    setValue({from:getRangeMonths(6),to:new Date()});
  }, []);
  
  /**Reaciuona al click en año asignando 12 meses menos */
  const year = React.useCallback(() => {
    setValue({from:getRangeMonths(12),to:new Date()});
  }, []);
  
  /**
   * determina el valor actualñ selecionado
   */
  const itemSelected = React.useMemo(() => {
    let temp = valueString?.split("-") ?? [];
    return getItemSelectedRange(temp);
  },[valueString]);

  return (visible ? <div className="container-options-date-range">
    <ClickAwayListener clickOutside={close }>
      <div className="container-options-date-range__container-cols">
        <div className={"container-options-date-range__container-cols__col-1"}>
          <Button className={`button-options-range ${itemSelected === "TODAY"?"button-options-range--selected":""}`} label="Hoy" onClick={ today }/>
          <Button className={`button-options-range ${itemSelected === "WEEK"?"button-options-range--selected":""}`} label="Semana" onClick={ week }/>
          <Button className={`button-options-range ${itemSelected === "MONTH"?"button-options-range--selected":""}`} label="Mes" onClick={ month }/>
        </div>
        <div className={"container-options-date-range__container-cols__col-1"}>
          <Button className={`button-options-range ${itemSelected === "3-MONTHS"?"button-options-range--selected":""}`} label="Trimestre" onClick={ month3 }/>
          <Button className={`button-options-range ${itemSelected === "6-MOUTHS"?"button-options-range--selected":""}`} label="Semestre" onClick={ month6 }/>
          <Button className={`button-options-range ${itemSelected === "YEAR"?"button-options-range--selected":""}`} label="Año" onClick={ year }/>
        </div>

        <div className={"container-options-date-range__container-cols__col-2"}>
          <DayPicker
            className="Selectable"
            selectedDays={[modifiers.from,modifiers]}
            onDayClick={dayClick}
            modifiers={modifiers}
          />
        </div>
        <div className={"container-options-date-range__container-cols__col-3"}>
          { valueString }
        </div>
        <div className={"container-options-date-range__container-cols__col-4"}>
          <Button className="button-controls-range button-controls-range--gray" label="Cerrar" onClick={ close} />
          <Button className="button-controls-range button-controls-range--gray" label="Borar" onClick={ reset} />
          <Button className="button-controls-range" label="OK" onClick={ onClickOK }/>
        </div>
      </div>
    </ClickAwayListener>
  </div>:null);
 });