import React from "react";
import { action, objectKV } from "../../../utils/interface";

const SideBarContext = React.createContext(undefined);

export const SideBarProvider = (props:any) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const setStateSideBar = (v?:{isOpen?:boolean}) => {
        if(v?.isOpen === undefined) return;
        setIsOpen(v.isOpen);
    };
    
    let value = React.useMemo(()=>({state:{isOpen},setStateSideBar}), [isOpen, setStateSideBar]);
    return <SideBarContext.Provider value={value} {...props} />
};

export const useSideBarContext = ():{state:objectKV, setStateSideBar: (v?:{isOpen?:boolean}) => any, error:action } => {
    const context = React.useContext(SideBarContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};