import React from "react";
import "./Load.scss";

interface propsLoadCircular {
    className?:string;
}

const LoadCircular = (props: propsLoadCircular) => {
    return (
        <div className={`load-rotate ${props.className}`}>
            <i className="icon-actualizar" />
        </div>
    );
}

export default LoadCircular;