import React from 'react';
import "./Table.scss";

interface propsTableComponents {
    children?: JSX.Element[] | JSX.Element;
    [v:string]:any;
}

export const TableBasic = React.memo((props:propsTableComponents) => {
    const { children,className, ...all } = props;
    return <div className={`tableBasic ${className}`} {...all}>{props.children}</div>
});

export const TrBasic = React.memo((props:propsTableComponents) => {
    const { children,className, ...all } = props;
    return <div className={`trBasic ${className??""}`} {...all}>{props.children}</div>
});

export const TDTHBasic = React.memo((props:propsTableComponents) => {
    const { children,className, ...all } = props;
    return <div className={`tdthBasic ${className??""}`} {...all}>{props.children}</div>
});

export const THeadBasic = React.memo((props:propsTableComponents) => {
    const { children,className, ...all } = props;
    return <div className={`tHeadBasic ${className??""}`} {...all}>{props.children}</div>
});

export const TBodyBasic = React.memo((props:propsTableComponents) => {
    const { children,className, ...all } = props;
    return <div className={`tBodyBasic ${className??""}`} {...all}>{props.children}</div>
});

export const TFootBasic = React.memo((props:propsTableComponents) => {
    const { children,className, ...all } = props;
    return <div className={`tFootBasic ${className??""}`} {...all}>{props.children}</div>
});
