import React from "react";
import usePortal from "../../hook/portal-hook";
import ReactDOM from "react-dom";
import { action } from "../../../utils/interface";
import "./Modal.scss";

interface propsModal {  
    children?: JSX.Element[] | JSX.Element;
    /**Evita que aparezca un div en la parte de atras del modal, este es util para detectar el click fuera de si mismo*/
    backGroundDisabled?:boolean;
    /**Indica el estado de la modal */
    visible:boolean;
    /**Indica el color que tendra elbackgroud*/
    backGroundColor?:string;
    /**Se llama con el click fuera de la modal*/ 
    clickOutside?:(v:action) => any;
    /**Se usa para que la modal no este centrada*/ 
    noCenter?:boolean;
}

interface propsContainer {  
    children?: JSX.Element[] | JSX.Element;
    /**Indica el estado de la modal */
    visible:boolean;
    /**Se usa para que la modal no este centrada*/ 
    noCenter?:boolean;
}

export const ChildrenContainer = (props:propsContainer) => {
    return ( 
    <div className={`${!props.noCenter?`view-children ${props.visible? "view-children__enter": "view-children__leave"}`:""} `}>
        {props.children}
    </div>)
};

 export const Modal = React.memo((props:propsModal) => {
    const el = usePortal("modal-root");

    //Esta indicador se usa con retardo para permitir la animacion.
    //Pero a la pestaña de contenedor se le avisa en el momento para que ejecute la animacion 
    const [visible, setVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        let idTime:any;
        if(props.visible) {
            setVisible(true);
        }
        else {
            idTime = setTimeout(() => setVisible(false), 150);
        }
        
        return () => {
            if(idTime) clearTimeout(idTime);
        };
    }, [props.visible]);

    const _onClick = () => {
        if(props.clickOutside) props.clickOutside({type:"CLICK_OUTSIDE", value:true});
    };

    return (visible? ReactDOM.createPortal(
            <React.Fragment>
                {!props.backGroundDisabled?<div onClick={_onClick} className="view-back-ground" style={{background:props.backGroundColor}}></div>:null}
                <ChildrenContainer noCenter={props.noCenter} visible={props.visible}>
                    {props.children}
                </ChildrenContainer>
            </React.Fragment>,
            el):null
        );
});
