import React from 'react';
import { Layout } from '../../Componets/General/Layout/Layout';
import { Table } from '../../Componets/Basic/Table/Table';
import { fields, queryDataTravel, fieldExcel } from '../Home/Home.conf';
import { useLazyQueryToSever } from '../../Componets/hook/query-hook';
import { useUser } from '../../Componets/hook/user-hook';
import { action, confTable, objectKV } from '../../utils/interface';
import { createFilters, parceOrders, FiltersOptions } from '../../Componets/General/UtilsView/Utils-view';
import { FactoryFilters, Factory, useSysState, FactoryFiltersHistory } from '../Home/Home.factory';
import { Trazability } from '../Home/Home';


export const HistoryView = React.memo(() => {

    const tableRef =  React.useRef(null);

    const [hiddenFilters, sethiddenFilters] = React.useState<boolean>();

    const [loadData,{data,loading,refetch,error}] = useLazyQueryToSever({query:queryDataTravel});

    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);
    let [dataSelected, setDataSelected] = React.useState<Array<objectKV>>([]);

    const { user } = useUser();

    const { sys:sysState } = useSysState([1,2,3,5,6,8,9,10,11,12,13]);
    
    const initValues:objectKV = {
        numReg:20,
        currentPage:1,
        visible:false
    };

    const reducer = (state:objectKV, action:action) => {
        switch(action.type){
            case "ADD_FILTERS":
                let filters = createFilters(action.value);
                if(JSON.stringify(filters) === JSON.stringify(state.filters)) return state;
                return Object.assign({}, state, {filters, currentPage:1});
            case "SET_ORDER":
                if(!action.value) return state;
                return Object.assign({}, state, {orders:action.value});
            case "SET_NUM_REG":
                return Object.assign({}, state, {numReg:+action.value ?+action.value:10,currentPage:1});
            case "CLICK_ROW": 
                if(+(action?.value?.id ?? 0)) return Object.assign({}, state,{id:+action?.value?.id, visible:true});
                return state;
            case "CLICK_OUT_SIDE":
                if(state.visible) return Object.assign({}, state,{ visible:false});
                return state;
            case "ADD_CURRENT_PAGE":
                //esto dos sentencias se quedan de esta forma hasta que se decida hacer un cambio en los valores que se selecionan. 
                setIdSelected([]);
                setDataSelected([]);
                return Object.assign({}, state, {currentPage:action.value});
            default:
                throw new Error("action desconocida");
        }
    };

    const [state, dispatch] = React.useReducer(reducer, initValues);

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            el?.resetFilters()
        }
    };

    const _valuesSelected = (v:Array<string | number>) => {
        if(v[0] === Infinity && data?.viajesClientesInfo?.data?.length) {
            let ids = data.viajesClientesInfo.data.map((item:objectKV) => item && item.id).filter(Boolean);
            //Nota esto se queda asi temporalmente hasta que se decida cambiar la opcion para selecionar.
            setDataSelected(data.viajesClientesInfo.data.filter(Boolean));
            setIdSelected([...ids]);
        }
        else {
            setIdSelected([...v]);
            if(data?.viajesClientesInfo?.data?.length)
            setDataSelected(data?.viajesClientesInfo.data.filter((item:objectKV) => v.includes(item.id)).filter(Boolean));
        }
    };

    React.useEffect(() => {

        let { filters, numReg, currentPage, orders } = state;
        //Se parcean los campos de orden que traen una estructura diferente de el state
        orders = parceOrders(orders);
        if(!sysState) return;
        if (filters && numReg && currentPage && user.clientId) {
            let _filters, tempValue: any = filters.find((item: false | { field: string; value: any; }) => item && item.field === "idsysestado");
            if(!tempValue?.value && sysState?.length) _filters=[...filters,...sysState.map((item:objectKV) => ({field:"idsysestado",value:item.id+""}))];
            else _filters = filters;
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            loadData({variables:{pagination:{fields:state.numReg,page:state.currentPage}, filters:_filters, orders,idcliente:user.clientId }})
        }
    }, [state.filters, state.numReg, state.currentPage, JSON.stringify(state.orders),sysState ]);

    return (
        <Layout
        title="Historial"
        total={data?.viajesClientesInfo?.totalreg}
        numReg={state.numReg}
        goToPage={dispatch}
        right={
            <FiltersOptions 
            filters={state.filters}
            reset={resetFilters} 
            hiddenFilters={sethiddenFilters}
            hidden={!!hiddenFilters} 
            nameDoc="Viajes"
            data={dataSelected ?? []}
            execlFields={fieldExcel}
            query={queryDataTravel}
            variables={{filters:state?.filters?.length?state?.filters:undefined,idcliente:user?.clientId, orders:Object.values(state?.orders??{})?.length?[{type:state?.orders?.order,field: state?.orders?.field}]:[{type: "ASC", field: "id"}]}}
            path="viajesClientesInfo.data"
            />
        }
        pageCurrent={state.currentPage}
        >
            <Trazability visible={state.visible} id={state.id} dispatch={dispatch} />
            <Table 
            ref={tableRef}
            fields={fields.filter((item:confTable) => !["advance","ETA"].includes(item.field))}
            factory={Factory}
            getFilters={dispatch} 
            orderClick={dispatch}
            idSelected={idSelected}
            valuesSelected={_valuesSelected}
            factoryFilters={FactoryFiltersHistory} 
            hiddenFilters={hiddenFilters}
            isSelectable
            clickRow={dispatch}
            loading={loading}
            orderableField={state.orders} 
            data={data?.viajesClientesInfo?.data ?? []}
            />
        </Layout>
    );
});
