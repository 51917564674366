import React from "react";
import { action } from "../../../utils/interface";
import { setStoreInfo, delStoreInfo, getStoreInfo, delStoreInfoUtils } from "./../../../utils/store";
import { useHistory } from "react-router-dom";
import { useClientApolloGranada } from "../../hook/client-apollo-hook";

interface userInterface {
    roll?:number; 
    token?:string; 
    name?:string; 
    id?:number; 
    idcompany?:number;
    logo?: string;
    minutes?: number;
    clientId?: number;
}

const UserContext = React.createContext(undefined);

export const UserProvider = (props:any) => {

    let history = useHistory();

    let client = useClientApolloGranada();

    const [user, setUser] = React.useState<userInterface>();
    const [error, setError] = React.useState<action>();
    const [minutes, setMinutes] = React.useState<number>(0);
    const [totalMinutes, setTotalMinutes] = React.useState<number>(45);

    React.useEffect(() => {
        setUser(getStoreInfo());
    }, []);

    const _mousemove = () => {
        setMinutes((min: number) => 0);
    };

    const login = (v:userInterface) => {
        setStoreInfo(v);
        if (v.minutes) setTotalMinutes(+(v.minutes??45));
        let _user = getStoreInfo();
        if(_user.token){
            return setUser(_user);
        } 
        return logout();
    };

    // React.useEffect(() => {
    //     window.addEventListener("mousemove",_mousemove,false);
    //     let id = setInterval(() => {
    //         setMinutes((min: number) => ++min);
    //     }, 60000);
    //     return () => {
    //         window.removeEventListener("mousemove",_mousemove,false);
    //         clearInterval(id);
    //     };
    // }, []);
    
    // React.useEffect(() => {
    //     if (totalMinutes > minutes) return;
    //     logout();
    //     window.location.reload();
    //  },[minutes]);

    const logout = () => {
        client.clearStore().then(() => {
            delStoreInfo();
            delStoreInfoUtils();
            let _user = getStoreInfo();
            if(_user.token) return setError({type:"ERROR", value:"No se pudo llevara a cabo el logout"});
            setUser({});
            return history.push("/");
        }).catch(() => setError({type:"ERROR", value:"No se pudo llevara a cabo el logout"}));
    };

    let value = React.useMemo(()=>({login, logout, user, error}), [user, error]);
    return <UserContext.Provider value={value} {...props} />
};

export const useUserContext = ():{login:(v:userInterface) => any, logout:()=>any, user:userInterface, error?:string} => {
    const context = React.useContext(UserContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};