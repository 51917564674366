import React from "react";
import "./Notification.fom.scss";

export const NotificationError = (props:{message:string}) => {
    return <NotificationBasic icon="icon-close color-red" message={props.message} />
};

export const NotificationSucces = (props:{message:string}) => {
    return <NotificationBasic icon="icon-check color-green" message={props.message} />
};

export const NotificationBasic = (props:{message:string, icon:string}) => {
    return (
        <div className="notification-container-basic">
            <div className="notification-container-basic__icon">
                <i className={props.icon} />
            </div>
            <div className="notification-container-basic__message">{props.message}</div>
        </div>
    );
};