import React from "react";
import usePortal from "../../hook/portal-hook";
import ReactDOM from "react-dom";
import { useNotification } from "../../hook/notification-hook";
import "./Notification.scss";

    
export const NotificationElement = (props:{children?: JSX.Element[] | JSX.Element; message?:string}) => {
    return (
        <div className="notification">
            {props.children ?? props.message}
        </div>
    );
};

export const NotificationContainer = () => {
    const el = usePortal("notification-root");
    const {notification} = useNotification();
    return ReactDOM.createPortal(<div className="notification-container">
        {notification?.map && notification.map((item:any) => <div className="notification-div-key" key={item.id}>{item?.notification?.element}</div> ?? null)}
    </div>,el);
};