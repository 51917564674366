import { gql, useApolloClient } from "@apollo/client";
import { objectKV } from "../../utils/interface";

/**
 * @description este hook se encarga de gestionar el cliente de graphql 
 * @returns
 */
export const useClientApolloGranada = () => {
    let client = useApolloClient();

    const clearStore = () => client.clearStore();

    const writeQuery = (v:{query:string,data:objectKV,variables?:any}) => client.writeQuery({query:gql`${v.query}`,data:v.data,variables:v.variables});

    const readQuery = (v:{query:string,variables?:any}) => client.readQuery({query:gql`${v.query}`,variables:v.variables});

    const readFragment = (v:{fragment:string,fragmentName?:string,id?:string}) => client.readFragment({fragment:gql`${v.fragment}`,fragmentName:v.fragmentName,id:v.id});

    return {clearStore,readFragment,writeQuery,readQuery};
  };