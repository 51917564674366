import React from "react";
import ToolTip from "./../ToolTip/ToolTip";
import "./Icon.tooltip.scss";

interface propsIcon {
    /**Son las clases de scss para el icono*/
    className?:string;
    /**Es el mesnsaje que se mostrara en el tooltip  */
    message?:string;
    /**Este valor es util en el caso que se decee implementar un valor diferente al icon */
    content?: JSX.Element[] | JSX.Element | null;
    /**Son los posibles hijos del icono */
    children?: JSX.Element[] | JSX.Element | null;
    /**Este numero que se usa para estabilizar el tooltip, se usa principalemete cuando el tooltip esta cerca de los limites de la panatalla  */
    stabilizer?:number;
}

/**@description esta funcion presenta el componente de icon con tooltip
 * @version 1.0
 */
const Icon = React.memo((props:propsIcon) => {

    /**Estado que representa la distancia del componente con la parte superior de la pantalla */
    const [top, setTop] = React.useState<number>(0);

    /**Estado que representa la distancia del componente con la parte izquierda de la pantalla */
    const [left, setLeft] = React.useState<number>(0);

    /**Estado que representa si esta o no visible el tooltip     */
    const [visible, setVisible] = React.useState<boolean>(false);

    /**Controla el evento de maouse capture  */
    const _onMouseMoveCapture = (e:any) => {
        if(e){
            let _top = 0,_left = 0;
            /**Calculo de la distancia en Y */
            if(+e.clientY) {
                _top = e.clientY;
                if((window.innerHeight - e.clientY) < 100) {
                    _top = e.clientY - (window.innerHeight - e.clientY) - (props.stabilizer ?? 0);
                    // _left = +e.clientX - 1000;
                }
            }
            /**Calculo de las distancias en X */
            if(+e.clientX) {
                _left = +e.clientX;
                if((window.innerWidth - e.clientX) < 100) {
                    _left = e.clientX - (window.innerWidth - e.clientX);
                    // _left = +e.clientX - 1000;
                }
            }
            setTop(_top);
            setLeft(_left);
        }
        if(!visible) setVisible(true);
    };

    /**Maneja el vento mouse leave */
    const _onMouseLeave = () => {
        setVisible(false);
        setTop(0);
        setLeft(0);
    };

    return <React.Fragment>
        {props.content?
        <label onMouseMoveCapture={_onMouseMoveCapture} onMouseLeave={_onMouseLeave}>{props.content}</label>:
        <i className={props.className} onMouseMoveCapture={_onMouseMoveCapture} onMouseLeave={_onMouseLeave} />}
        {props.message || props.children? <ToolTip visible={visible}  key={2} top={top} left={left} >
            {props.children ?? <label className="text-label">{props.message} </label>}
        </ToolTip>:null}
    </React.Fragment>
});

export default Icon;