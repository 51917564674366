export enum store {
    /**
     * esta es la llave con la que se guaradar la session en local stores
     */
    KEY_SESSION="SESSION_CTRL_CLIENTS",

    //esta es la key que guarda querys en la cache 
    KEY_UTILS="SESSION_CC_UTILS",

    KEY_CONFIG_TABLE="SESSION_CCCT",

    /**
     * este es el codigo que indica que no hay nada en local store
     */
    STORE_IS_NULL=11

}
