import React from 'react';
import { Layout } from '../../Componets/General/Layout/Layout';
import { Table } from '../../Componets/Basic/Table/Table';
import { fields, queryDataTravel, fieldsTraza, queryTravel, fieldExcel, fieldExcelTraza } from './Home.conf';
import { useLazyQueryToSever } from '../../Componets/hook/query-hook';
import { useUser } from '../../Componets/hook/user-hook';
import { action, objectKV } from '../../utils/interface';
import { Modal } from '../../Componets/Basic/Modal/Modal';
import "./Home.scss";
import { createFilters, parceOrders, FiltersOptions, ExportExcel } from '../../Componets/General/UtilsView/Utils-view';
import { FactoryFilters, Factory, useSysState } from './Home.factory';
import TextView from '../../Componets/Basic/Text/TextView';
import { Close } from '../../Componets/General/FormsUtils/Buttons.form';
import { ImageViwerByKeys } from '../../Componets/Basic/Image/ImageViwer';
import { useHistory } from 'react-router';
import Button from '../../Componets/Basic/Button/Button';
import Icon from '../../Componets/Basic/Icon/Icon.tooltip';


export const Home = React.memo(() => {

    const tableRef =  React.useRef(null);

    const [hiddenFilters, sethiddenFilters] = React.useState<boolean>();

    const [loadData,{data,loading,refetch,error,called}] = useLazyQueryToSever({query:queryDataTravel});

    let [idSelected, setIdSelected] = React.useState<Array<number | string>>([]);
    let [dataSelected, setDataSelected] = React.useState<Array<objectKV>>([]);

    const { user } = useUser();

    const { sys:sysState } = useSysState([4,7]);
    
    const initValues:objectKV = {
        numReg:20,
        currentPage:1,
        visible:false,
        orders:{
            id: 0,
            order: "ASC",
            field: "id" 
        }
    };

    const reducer = (state:objectKV, action:action) => {
        switch(action.type){
            case "ADD_FILTERS":
                let filters = createFilters(action.value);
                if(JSON.stringify(filters) === JSON.stringify(state.filters)) return state;
                return Object.assign({}, state, {filters, currentPage:1});
            case "SET_ORDER":
                if(!action.value) return state;
                return Object.assign({}, state, {orders:action.value});
            case "SET_NUM_REG":
                return Object.assign({}, state, {numReg:+action.value ?+action.value:10,currentPage:1});
            case "CLICK_ROW": 
                if(+(action?.value?.id ?? 0)) return Object.assign({}, state,{id:+action?.value?.id, visible:true});
                return state;
            case "CLICK_OUT_SIDE":
                if(state.visible) return Object.assign({}, state,{ visible:false});
                return state;
            case "ADD_CURRENT_PAGE":
                //esto dos sentencias se quedan de esta forma hasta que se decida hacer un cambio en los valores que se selecionan. 
                setIdSelected([]);
                setDataSelected([]);
                return Object.assign({}, state, {currentPage:action.value});
            default:
                throw new Error("action desconocida");
        }
    };

    const [state, dispatch] = React.useReducer(reducer, initValues);

    const resetFilters = ()=>{
        let el:any = tableRef?.current;
        if(el?.resetFilters){
            el?.resetFilters()
        }
    };

    const _valuesSelected = (v:Array<string | number>) => {
        if(v[0] === Infinity && data?.viajesClientesInfo?.data?.length) {
            let ids = data.viajesClientesInfo.data.map((item:objectKV) => item && item.id).filter(Boolean);
            //Nota esto se queda asi temporalmente hasta que se decida cambiar la opcion para selecionar.
            setDataSelected(data.viajesClientesInfo.data.filter(Boolean));
            setIdSelected([...ids]);
        }
        else {
            setIdSelected([...v]);
            if(data?.viajesClientesInfo?.data?.length)
            setDataSelected(data?.viajesClientesInfo.data.filter((item:objectKV) => v.includes(item.id)).filter(Boolean));
        }
    };

    React.useEffect(() => {

        let { filters, numReg, currentPage, orders } = state;
        //Se parcean los campos de orden que traen una estructura diferente de el state
        orders = parceOrders(orders);
        if(!sysState) return;
        if (filters && numReg && currentPage && user.clientId) {
            let _filters, tempValue: any = filters.find((item: false | { field: string; value: any; }) => item && item.field === "idsysestado");
            if(!tempValue?.value && sysState?.length) _filters=[...filters,...sysState.map((item:objectKV) => ({field:"idsysestado",value:item.id+""}))];
            else _filters = filters;
            // variables: fields:Int, page:Int, filters: [FilterInput], orders: [OrderInput]
            loadData({variables:{pagination:{fields:state.numReg,page:state.currentPage}, filters:_filters, orders:[orders],idcliente:user.clientId }})
        }
    }, [state.filters, state.numReg, state.currentPage, JSON.stringify(state.orders),sysState ]);

    return (
        <Layout
        title="Viajes"
        total={data?.viajesClientesInfo?.totalreg}
        numReg={state.numReg}
        goToPage={dispatch}
        right={
            <FiltersOptions 
            filters={state.filters}
            reset={resetFilters} 
            hiddenFilters={sethiddenFilters}
            hidden={!!hiddenFilters} 
            nameDoc="Viajes"
            data={dataSelected ?? []}
            execlFields={fieldExcel}
            query={queryDataTravel}
            variables={{filters:state?.filters?.length?state?.filters:undefined,idcliente:user?.clientId, orders:Object.values(state?.orders)?.length?[{type:state?.orders?.order,field: state?.orders?.field}]:[]}}
            path="viajesClientesInfo.data"
            />
        }
        pageCurrent={state.currentPage}
        >
            <Trazability visible={state.visible} id={state.id} dispatch={dispatch} />
            <Table 
            ref={tableRef}
            fields={fields}
            factory={Factory}
            getFilters={dispatch} 
            orderClick={dispatch}
            idSelected={idSelected}
            valuesSelected={_valuesSelected}
            factoryFilters={FactoryFilters} 
            hiddenFilters={hiddenFilters}
            isSelectable
            clickRow={dispatch}
            loading={loading||!called}
            orderableField={state.orders} 
            data={data?.viajesClientesInfo?.data ?? []}
            />
        </Layout>
    );
});

export const Trazability = React.memo((props:{visible:boolean,id?:string | number, dispatch:(v:action) => any}) => {

    const [loadData, {data, loading, error}] = useLazyQueryToSever({query:queryTravel});

    let history = useHistory();
    
    React.useEffect(() => {
        if(props.visible && +(props.id ?? 0)) {
            loadData({variables:{id:+(props.id ?? 0)}});
        }
    }, [props.visible]);
    
    const _clickOutside = () => {
        props.dispatch({type:"CLICK_OUT_SIDE"});
    };
    
    const _clickClose = () => {
        props.dispatch({type:"CLICK_OUT_SIDE"});
    };
    
    const goToGallery = () => {
        let _id = +(props.id??0);
        if(_id)history.push(`/Home/Gallery/${props.id}`)
    };

    return (<Modal visible={props.visible} clickOutside={_clickOutside} backGroundColor="#b1b1b130">
        <div className="trazability">
            <div className="trazability__container">
            <div className="trazability__container__close">
                <Close onClick={_clickClose}/>
            </div>            
            <div className="trazability__container__driver">
                <div className="driver-info-client">
                    <TextView label="Conductor" text={data?.viajeClienteInfo?.nombreconductor}/>
                    <TextView label="Documento" text={data?.viajeClienteInfo?.documentoconductor}/>
                    <div>
                    </div>
                </div>
                <ImageViwerByKeys uri={data?.viajeClienteInfo?.fotoconductor} style={{width:"98%",height:"150px"}}/>
            </div>            
            <div className="trazability__container__vehicle">
                <div className="vehicle-info-client">
                    <TextView label="Placa" text={data?.viajeClienteInfo?.placa}/>
                    <TextView label="Tipo" text={data?.viajeClienteInfo?.tipo}/>
                    <TextView label="Marca" text={data?.viajeClienteInfo?.marca}/>                    
                    <TextView label="Modelo" text={data?.viajeClienteInfo?.modelo}/>
                    <TextView label="Color" text={data?.viajeClienteInfo?.color}/>
                    <TextView label="Trailer" text={data?.viajeClienteInfo?.trailerplaca}/>
                </div>
                <ImageViwerByKeys uri={data?.viajeClienteInfo?.vehiculofoto} style={{width:"98%",height:"150px"}}/>
            </div>            
            <div className="trazability__container__general">
                <TextView label="Nº Remesa" text={data?.viajeClienteInfo?.numeroremesa}/>
                <TextView label="Estado" text={data?.viajeClienteInfo?.estado}/>
                <TextView label="# Contenedor" text={data?.viajeClienteInfo?.numerocontenedor||"NA"}/>
                <TextView label="Ruta" text={data?.viajeClienteInfo?.ruta}/>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Button className="button-report" onClick={goToGallery} >
                        <Icon message={"Ir a galeria"} className="icon-fotos-cargadas"/>
                    </Button>
                    <ExportExcel classNameBtn="button-report" data={data?.viajeClienteInfo?.trazabilidad} query={queryTravel} path=' ' name='Trazabilidad' fields={fieldExcelTraza} />
                </div>
            </div>            
            <div className="trazability__container__observation">
                <TextView showCopy height={100} numLines={4} label="Observaciones" text={data?.viajeClienteInfo?.observaciones??"NA"}/>
            </div>
            {/* <div className="trazability__container__plate">
                <TextView label="Placa" text={data?.viajeClienteInfo?.placa}/>
            </div>
            <div className="trazability__container__status">
                
            </div>
            <div className="trazability__container__number">
                <TextView label="Nº Remesa" text={data?.viajeClienteInfo?.numeroremesa}/>
            </div>
            <div className="trazability__container__route">
                <TextView label="Ruta" text={data?.viajeClienteInfo?.ruta}/>
            </div>
            <div className="trazability__container__image-vehicle">
               
            </div>            
            <div className="trazability__container__image-driver">
                <ImageViwerByKeys uri={data?.viajeClienteInfo?.fotoconductor} style={{width:"100%",height:"100%"}}/>
            </div> */}
            <div className="trazability__container__title">
                Trazabilidad
            </div>
            <div className="trazability__container__table">
                <Table
                 factory={Factory}
                 fields={fieldsTraza}
                 loading={loading}
                 data={data?.viajeClienteInfo.trazabilidad}
                 />
            </div>
            </div>
        </div>
    </Modal>)
});


// const getDtaFetch = () => {

//     const header = new Headers();
//     header.append('Content-Type', 'application/json');
//     header.append("idapp", '15');
//     header.append("idempresa", '4');
//     header.append("Authorization", "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjZkM2E5NTZlOGNmZjMwZjkwMTlmNGFiOGE2N2NmMGZmYzVkZTI3NGJiNWNiMTY4MjA4NGY2ZWE3ZTZkMTk0NDllMWRiOTAyYzkxYjI3NWYwIn0.eyJhdWQiOiIxIiwianRpIjoiNmQzYTk1NmU4Y2ZmMzBmOTAxOWY0YWI4YTY3Y2YwZmZjNWRlMjc0YmI1Y2IxNjgyMDg0ZjZlYTdlNmQxOTQ0OWUxZGI5MDJjOTFiMjc1ZjAiLCJpYXQiOjE1OTM1NTc3NDUsIm5iZiI6MTU5MzU1Nzc0NSwiZXhwIjoxNTk0ODUzNzQ1LCJzdWIiOiIxMjAiLCJzY29wZXMiOltdfQ.AAwe3MGsnz2xBNVtNIwlt2O3MJAomicmLYIsqGcYcP1ndbCjUyzXVhZjUj0ZClHpZKUoW81FzyAL2B8t4-9vCTKQXJrFff5U52FPeMQIBln9xjlfq6zYpwM-KWrobOqyf9eLW5sQdzdLVDRkYnNxYZGr_YwuIKE950RqCld3DS562ZF818ioQs0lwX16SVLrdYP3gOpag6Y3pwg9DZQhShSOw4IPzC3pOquxlDGrmWYTeEpksgJvCQbbz6ZzD5J-tsRiII1pSW6rI97GcdxQqWk7CuXaydqeqOIS2hh-cA90PciCznStNhPMqSTJJL_1I6RzYXX-EqKH3W_yMayp8j8BtkiYQva9kGogWe3fb3ZqPCqEn5aER-ERlDo3V3lhqTk-QpYB3SNtYSB52DTgKVZPHA0jGz1L5bbC1G5-kXzg2SGt9NMwnfqKHJnMSmsj56BAVyxzF-QHkNqHDfs5SXJzpQYNB3NXDuoOoNKGORarxb92MwC6xK1ju6lYeLrwQ1wXhYrwQDSgDJW3jqBnDpq67YwEq8bCyVgIO6QdOKShiF6NjbXMHFHymtMfflwt2N7lLtv5HbtZbTdq9QazJM8Qjsa6kf6vylrhHizN-t9gv4jBs5UCx9VIibAi8Cucn0RcAE8F34Ok1PhiT6Cf0kYX24HK8wI6aavzYSrft_c" );

//     fetch("http://devctrltrafico.us-west-1.elasticbeanstalk.com/graphql",{
//         method:"POST",
//         headers:header,
//         body: JSON.stringify({ query: `query getTravels ($filters: [FilterInput],$orders: [OrderInput],$pagination: PaginationInput) {  
//             viajesClientesInfo(filters:$filters,orders:$orders,pagination:$pagination) {
//                 data {
//                   id
//                   idviaje
//                   documentocliente
//                   origen
//                   destino
//                   idsysestado
//                   placa
//                   estado
//                   ultimoreporte
//                   numeroremesa
//                   lugar
//                   fechahoradescarguesalida
//                   fechahoracitadescargue
//                   empresatransportadora
//                   idproximopuestocontrol
//                   planviaje {
//                     id
//                     puestocontro
//                     idpuestocontrol
//                     distancia
//                     duracion
//                     orden
//                   }
//                 }
//                 totalreg
//             }
//         }`, variables: {pagination: {fields: 4, page: 1}, filters: []} })
//     }).then(res => res.json()).then(console.log);
// };