
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { objectKV } from "./../../utils/interface";


interface propsUseQuery {
    variables?:objectKV;
    context?:objectKV;
    query:string;
    onCompleted?:(data: any) => void;
    fetchPolicy?: "cache-and-network" | "network-only" | "cache-first" | "cache-only"; // en caso de requerir mas politicas agregar
}

interface propsUseQueryV2 {
    variables?:objectKV;
    context?:objectKV;
    query:string;
    onCompleted?:(data: any) => void;
    fetchPolicy?: "cache-and-network" | "network-only" | "cache-first" | "cache-only"; // en caso de requerir mas politicas agregar
}

/**
 * @description este hook se debera encargar de realizar las consultas al servidor graphql 
 * @param v
 * @returns loading, error, data, refetch 
 */
export const useQueryToSever = (v:propsUseQuery) => {
       return useQuery(gql`${v.query}`, { 
           fetchPolicy: v.fetchPolicy ?? "cache-and-network",
           variables:v.variables,
           context:v.context
       });
  };

/**
 * @description este hook se debera encargar de realizar las consultas locales graphql 
 * @param v
 * @returns loading, error, data, refetch 
 */
export const useQueryToLocalForId = (query:string) => {
    let client = useApolloClient();

    const getData = (id?:number | string) => {
        let data;
        try {
            let dataTemp = client.readQuery({query:gql`${query}`});
            if(Array.isArray(data)){
                data = dataTemp.find((item:objectKV) => item.id === id);
            } else {
                data = dataTemp;
            }
        } catch (error) {
            console.warn(error);
            data = null;
        }
        return data;
    }
    return {getData};
  };

/**
 * @description este hook se debera encargar de realizar las consultas al servidor graphql 
 * @param v
 * @returns [loadData, { called, loading, data, refetch,fetchMore }]
 */
export const useLazyQueryToSever = (v:propsUseQuery) => {
    return useLazyQuery(gql`${v.query}`, { 
        fetchPolicy: v.fetchPolicy ?? "cache-and-network",
        variables:v.variables,
        context:v.context,
        onCompleted:v.onCompleted
    });
  };
/**
 * @description este hook se debera encargar de realizar las consultas al servidor graphql 
 * @param v
 * @returns [loadData, { called, loading, data, refetch,fetchMore }]
 */
export const useLazyQueryGranada = (v:propsUseQueryV2) => {
    return useLazyQuery(gql`${v.query}`, { 
        fetchPolicy: v.fetchPolicy ?? "cache-first",
        variables:v.variables,
        context:v.context,
        onCompleted:v.onCompleted
    });
  };