import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../Componets/Basic/Button/Button";
import { GalleryCard, getImage } from "../../Componets/Basic/Gallery/Gallery";
import Icon from "../../Componets/Basic/Icon/Icon.tooltip";
import { DateViwer, formatterDateTime } from "../../Componets/Basic/Text/DateView";
import { LayoutWithOutPage } from "../../Componets/General/Layout/LayoutWithOutPage";
import { useGeneralStore } from "../../Componets/hook/general-store-hook";
import { useLazyQueryToSever } from "../../Componets/hook/query-hook";
import { createZip, downloadBlob } from "../../utils/utils";
import { queryDocuments as GET_TRAVEL_DOCUMENTS,queryUrlReport as GET_URL_REPORTS } from "./Gallery.conf";
import "./Gallery.scss";

interface trazability {
    id?:null | string | number;
    idviaje?:null | string | number;
    lugar?:null | string;
    puestocontrol?:null | string;
    sysevento?:null | string;
    evento?:null | string;
    idsysevento?:null | string | number;
    fechahora?:null | string | number;
    novedad?:null | string;
    documentosreporte?:null | Array<{
                miniatura?:string;
                archivo?:string;
    }>
}

export const arrayToMatrix = (array:Array<any>, columns:number = 1) => {
    let _array:Array<any> = [];
    let clm:Array<any> = [];
    array.forEach((item:any, index:number) => {
        clm.push(item);
        if(((index+1) % columns === 0) || index+1 === array.length){
            _array.push([...clm]);
            clm = [];
        }
    });

    return [..._array];
};

export const ImagesReport = React.memo((props:trazability) => {

    const [loadData, { data:dataRequest }] = useLazyQueryToSever({query:GET_URL_REPORTS,fetchPolicy:"cache-first"});

    const {state:storeImages,setData,getData} = useGeneralStore();

    const [images,setImages] = React.useState<Array<{
        miniatura?:string;
        archivo?:string;
        key?:string;
    }>>([]);

    const _onClickCapture = React.useCallback(() => {
        
    },[]);

    // React.useEffect(()=>console.log("heres",storeImages),[storeImages])

    const _id = React.useMemo(() => +(props.id??0),[props.id]);

    React.useEffect(() => {
        if(_id) loadData({variables:{idreporte:_id}});
    },[_id]);

    React.useEffect(() => {
        if(!dataRequest?.getSignedURLReport?.length) return setImages( []);
        Promise.all(dataRequest?.getSignedURLReport?.map(async (item:any) => {
            // let uri = await getImage("https://ctrl-trafico-90b382fb-3665-4548-b7e4-efd77495d06b.s3.us-west-2.amazonaws.com/4/1629836580447-32-0-index.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXVC42GVKBBZX3DK2%2F20210824%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20210824T203017Z&X-Amz-Expires=60&X-Amz-Signature=d3376ca5973ab912b5277c25f680f004e9551fe3ff00fa83e0f80edf39922e79&X-Amz-SignedHeaders=host");
            let uri = await getImage(item?.url);
            if(uri)setData({type:"SET_DATA",value:{key:item.key,value:uri}});
            else uri = getData(item.key);
            return {miniatura:uri,key:item.key,archivo:uri};
        }))
        .then((res:any)=> {
            setImages(res)
        })
    },[dataRequest]);

    return (<div className="container-images-report">
            <div className="container-images-report__info-report">
                <label onClickCapture={_onClickCapture} className="report-type">{props.puestocontrol ?? props.sysevento ?? props.evento ?? props.id}</label>{"  "}
                <DateViwer data={{date:props.fechahora}} path="date"/>
            </div>
            {
                <GalleryCard images={images} name={`${props.puestocontrol ?? props.sysevento ?? props.evento}-${props.fechahora?formatterDateTime(props.fechahora).replaceAll("/","-"):""}_${props.id}`.replaceAll(" ","-")} />
            }
        </div>);
});

export const GalleryView = () => {
    /**
     * en esta variable se almacenara el id del viaje
     */
    const params:any = useParams();

    const id = params.id;
    let history = useHistory();
    const {state:storeImages,setData} = useGeneralStore();


    const [loadData, { data:dataRequest }] = useLazyQueryToSever({query:GET_TRAVEL_DOCUMENTS,fetchPolicy:"cache-first"});

    const reports = React.useMemo(() => dataRequest?.viajeClienteInfo?.trazabilidad?.filter((item:any) => !!item.numberdocs)??[],[dataRequest]);

    React.useEffect(() => {
        if(loadData && +id) loadData({variables:{id:+id}});
    }, [id]);

    React.useEffect(() => {
        setData({type:"SET_DATA",value:{key:"images",value:{}}});
    },[]);

    const _onClickDownloadAll = React.useCallback(() => {
        let values:any = [];
        Object.keys(storeImages.images??{}).forEach((item:any) => {
            let temp = storeImages.images[item];
            if(!Array.isArray(temp)) return;
            temp.forEach((item2:any) => {
                values.push({
                    data:fetch(item2.archivo).then((res:any)=>res.blob()),
                    name:`${item2.key}`.split("/").reverse()[0],
                    path:item
                });
            })
        });
        createZip(values).then((res:any) => downloadBlob(res, `viaje-${id}-${new Date().getTime()}.zip`));
    },[storeImages]);

    const goToTravel = () => {
        if(id)history.push(`/Home/Viaje/${id}`)
    };

    return ( <LayoutWithOutPage
    right={<div className="buttons-options-galerry-right"><Button onClick={_onClickDownloadAll} className="button-download "><Icon className="icon-descargar" message="Descargar todo"/></Button></div>}
    title={<section className="title-gallery-view">{`Galeria del viaje N ${id}`}</section>}
    >
        <div className="gallery gallery-view">
            <div className="gallery-view__reports">
                {reports.map((item:trazability, index:number) => <ImagesReport key={item.id} {...item}/>)}
            </div>
             {/* <div className="gallery-view__info">

            </div>  */}
        </div> 
    </LayoutWithOutPage>
    );
};