import * as React from "react";
import { objectKV,confTable, orderableField, factorFilter, action } from "./../../../utils/interface"
import { destructor, formatterTimeZoneDate } from "./../../../utils/utils";
import { useInput, useInputBoolean } from "./../../../Componets/hook/input-hook";
import InputText from "../Input/InputText";
import NoData from "./../NotFound/NoData";
import "./Table.scss"
import { DateViwer } from "../Text/DateView";
import InputCheck from "../Input/InputCkeck";
// import { usePrevious } from "../../hook/prevValue-hook";
import TextView from "../Text/TextView";
import { InputSelect } from "../Input/InputSelect";
import { DateRange } from "../Input/DateRange";
import { TableBasic, TBodyBasic, TDTHBasic, THeadBasic, TrBasic } from "./TableBasic";


interface TableProps {
    /**
     * es true mientras la tabla esta cargando
     */
    loading?: boolean;
    /** Esto es la data que se renderizara en la tabla */
    data: Array<objectKV>;
    /** Esto es la data que se renderizara en la tabla */
    hiddenFilters?: boolean;
    /**
     * Configuracion de la tabla, define los headers de la tabla
     */
    fields: Array<confTable>;

    /**
     * Esta funcio evalua si un valor puede ser seleccionable o no 
     */
    getIsSelectable?:(data:objectKV) => boolean;

    /**
     * Esta funcion debe retornar un componente, esto es para los casos especiales que requieren componentes custom
     */
    factory?: (props:{ field: confTable, data:objectKV })=> (JSX.Element | null ) ;

    /**
     * Esta funcion debe retornar un componente, esto es para casos especiales en los filtros, los select se deben crera por este 
     * metodo, ya que son componentes muy complejos, si retorna un valor este se pondra en el campo que se desea, si no retorna o retorna un falsy
     * se pondra el compoennte input por defecto
     */
    factoryFilters?: (props:factorFilter )=> (JSX.Element | null ) ;

    /**
     * indica que field es el que esta ordenando y si es desc o asc
     */
    orderableField?:orderableField;

    /**
     * esta es la funcion que se llama al hacer click en un campo que es ordenable
     */
    orderClick?:(v:action)=>any;

    /**
     * esta funcion recibe los filtros actuales
     */
    getFilters?:(v:action)=>any;

    /**
     * esta funcion recibe los valores de la fila que se ha clikeado.
     */
    clickRow?:(v:action)=>any;

    //NOTA los siguientes tres valores se deben pasar si la tabla permite la seleccion 
    /**
     * Indica que id estas seleccionados
     */
    idSelected?:Array<string | number>;

    /**
     * Indica si la tabla se puede selecionar o no
     */
    isSelectable?:boolean;

    /**
     * Devuelve los cambios Hechos en la seleccion, cuando se le da click a select all devuelve un array de una posicion con Inifinity
     */
    valuesSelected?:(v:Array<string | number>) => any;

    /**representa los posibles hijos de la tabla */
    children?: JSX.Element[] | JSX.Element;

    /**
     * Esta funcion permite crear un estilo a cada row.
     */
    rowStyleMaker?:(v?:objectKV,index?:number) => objectKV|undefined;

    [v:string]:any;
}

interface ElementProps {
    data: objectKV;
    field: confTable;
    factory?: (props:{field: confTable, data:objectKV })=>(JSX.Element | null);
}

interface RowProps {
    data:objectKV;
    getIsSelectable?:(data:objectKV) => boolean;
    index:number;
    /**
     * Configuracion de la tabla, define los headers de la tabla
     */
    fields: Array<confTable>;
    factory?: (props:{field: confTable, data:objectKV })=>(JSX.Element | null);
    onClick:(v:action) => any;
    selected?:boolean;
    showSelector?:boolean;
    clickSelect:(v:string | number) => any;

    /**
     * Esta funcion permite crear un estilo a cada row.
     */
     rowStyleMaker?:(v?:objectKV,index?:number) => objectKV|undefined;
}

interface RowElementProps {
    field:confTable;
    data:objectKV;
    factory?: (props:{field: confTable, data:objectKV })=>(JSX.Element | null);
    onClick:(v:action) => any;
}

/**
 * @description ordena un campo
 * @param tableField 
 * @param orderableField 
 */
export const order = ( tableField:confTable,orderableField?: orderableField) => {
    // se le indica qu no debe hacer algo si el campo no es ordenable
    if(!tableField.orderable) return;
    let newOrderableField:orderableField;

    if(!orderableField) {
        newOrderableField = {id:tableField.id ?? 0,order:"DESC",field:tableField.field ?? "void"};
    } else {
        if(tableField.id){
            if(tableField.id === orderableField.id) orderableField.order = orderableField.order === "ASC"?"DESC":"ASC"; 
            else orderableField = {...orderableField,order:"ASC",field:tableField.field ?? "void",id:tableField.id};
        } else if(tableField.field) {
            if(tableField.field === orderableField.field) orderableField.order = orderableField.order === "ASC"?"DESC":"ASC";
            else orderableField = {...orderableField,order:"ASC",field:tableField.field, id:tableField.id ?? 0};
        } 
        newOrderableField = orderableField;
    }
    return newOrderableField;
}

/** Este es un componente que retorna un */
export const ElementNa = () => {
    return (<label className="text-info text-table">NA</label>);
};

/**
 * @description Esta funcion pinta los valores de un texto en la pantalla
 * @param props 
 */
export const TextElement = (props:ElementProps) => {
    let value:Array <string> = [];

    if(props.field?.multiPath) value = props.field?.multiPath.map((item: string | Array <string>) => destructor(item,props.data) );
    
    if(props.field?.path) value = [...value,destructor(props.field.path,props.data)];
    let tempValue = value.map((item:string | number) => {
        if(item === 0) return item.toString();
        return item;
    }).filter(Boolean).join(props.field.concatToken ?? "");
    if(props.field?.useTooltip && tempValue) return <div style={{width:props.field.width,overflow:"hidden"}}><TextView text={tempValue} /></div>;
    return (tempValue || props.field?.voidValue ? <label className={`text-info text-table ${props?.field?.word? "white-space-nowrap": ""}`}>{tempValue??props.field?.voidValue}</label>:<ElementNa />);
};

/**
 * @description representa el tipo de dato fecha 
 * @param props 
 */
export const DateElement = (props:ElementProps) => {
    
    let date:any;

    if(props.field?.path) {
        date = destructor(props.field.path,props.data);
        if(+date) {
            date = new Date(formatterTimeZoneDate(+date).dateString).toISOString().slice(0,10).split('-').reverse().join('/');
        }
    }
    return (date? <label className="text-info text-table">{date}</label>:<ElementNa />);
};

/**
 * @description representa el tipo de dato fecha y hora en formato MM/DD-hh:mm
 * @param props 
 */
export const DateTimeElement = (props:ElementProps) => {

    if(props.field?.path) {
        return <DateViwer data={props.data} path={props.field.path} className="text-info text-table white-space-nowrap "/> ?? <ElementNa />;
    }
    return <ElementNa />;
};

/**
 * @description representa el tipo de dato fecha y hora en formato MM/DD-hh:mm
 * @param props 
 */
export const BooleanElement = (props:ElementProps) => {
    let data: any;

    if(props.field?.path) {

        data = destructor(props.field.path,props.data);

    }

    return <label className="text-info text-table">{data ? props.field.booleanValues?.isTrue ?? "SI": props.field.booleanValues?.isFalse ?? "NO"}</label>;
};

/**
 * @description representa el tipo de dato telefono
 * @param props 
 */
export const PhoneElement = (props:ElementProps) => {
    let data: any;
    if(props.field?.path) data = destructor(props.field.path,props.data);
    return data?<a href={`tel:${data}`}>{data}</a>:<label className="text-info text-table">NA</label>;
};

/**
 * @description esta funcion crea el filtro para los campos boolean
 */
export const InputFilterBoolean = React.memo((props:factorFilter) => {
    
    const { bind, confField } = props;
    return (
        <InputSelect style={{width:confField.width ?? 170}} name={confField.field} {...bind} disabled={!confField.filterable}
            data={[{value:"true",description:confField.booleanValues?.isTrue ?? "SÍ"},{value:"false",description:confField.booleanValues?.isFalse??"NO"}]}
            confSelect={{pathEmit:"value",pathOption:"description",textAling:"CENTER"}}
        />
    );
});

/**
 * @description crea los componentes que se van mostrar el la tabla.
 * @param props 
 */
export const ElementTable = (props:ElementProps) => {
    
    if (!props.field?.type) return props?.factory ? props?.factory({ field: props.field, data: props.data }) ?? null : null;
    else if (!props.field.path && !props.field.multiPath) return <ElementNa />;
    if (props.field?.type === "TEXT") return <TextElement {...props} />;
    if (props.field?.type === "DATE") return <DateElement {...props} />;
    if (props.field?.type === "DATE-TIME") return <DateTimeElement {...props} />;
    if (props.field?.type === "BOOLEAN") return <BooleanElement {...props} />;
    if (props.field?.type === "NUMERIC") return <BooleanElement {...props} />;
    if(props.field.type === "PHONE") return <PhoneElement {...props}/>;
    return <ElementNa />
};


/**
 * @description este es el elemento que muestra los input de filtros
 */
export const InputFilters = React.forwardRef((props:{
    confField:confTable,
    hidden?:boolean, 
    factoryFilters?: (props:factorFilter )=> (JSX.Element | null ),
    onChange: (v:action)=>any},
    ref:any) => {

    const {confField,onChange} = props;

    /**
     *  representa el valor del input, es un hook que lo administra
     */
    const {setValueKV,setValue,value,valueKV,bind,reset} = useInput(props.confField.valueFiltered,700,props.confField.field);
    

    React.useEffect(() => {
        if (props.confField.valueFiltered === value) return;
        setValueKV(props.confField.field, props.confField.valueFiltered ?? "");
        setValue(props.confField.valueFiltered ?? "");
    },[props.confField.valueFiltered]);

    /**
     * Reaciona al valor del input, para enviar el valor al padre
     */
    React.useEffect(() => {
        //HOOK_emit_filters_1 cuando se hace un reset el hook emite un valor {} evitamos que pase de este lugar con esta validacion
        //lo que indica que vacia el objeto en otra parte y se limita la emicion en este punto 
        let keys = valueKV && Object.keys(valueKV);
        if( keys?.length ) {
            if(onChange) onChange({type:"ADD_FILTER_IN_TABLE",value:valueKV});
        }
    },[JSON.stringify(valueKV)]);

    /**
     * Este hook crea las funciones que se comparten con el padre
     */
    React.useImperativeHandle(ref,()=>({
        reset
    }));

    const _Components = props.factoryFilters && props.factoryFilters({bind,confField});

    return (<div 
        className={`input-filters ${ props.hidden? "input-filters--hidden": "input-filters--show"} ${props.confField.textAling === "RIGHT"? "justify-content-right": props.confField.textAling === "CENTER"? "justify-content-center":""}`} >
            {
            _Components ?_Components:
            confField.type === "BOOLEAN"?
            <InputFilterBoolean bind={bind} confField={confField} /> :
            confField.type === "DATE" ?
            <DateRange style={{width:confField.width?confField.width:"",fontSize:confField.hiddenValue?0:undefined}} name={confField.field} {...bind}  disabled={!confField.filterable}/>:
            <InputText style={{width:confField.width?confField.width:"",fontSize:confField.hiddenValue?0:undefined}} name={confField.field} {...bind}  disabled={!confField.filterable}/>}
        </div>);
});

export const RowElement = React.memo((props:RowElementProps) => {
    return (
        <TDTHBasic
        onClick={() => !props.field.stopClick && props.onClick({type:"CLICK_ROW",value:props.data})}
        className={`table__row--body__field ${props.field.textAling === "RIGHT"? "justify-content-right": props.field.textAling === "CENTER"? "justify-content-center":""}`}
       >
            <ElementTable field={props.field} data={props.data} factory={props.factory}/>
        </TDTHBasic>
    );
});

/**
 * @description esta es la funcion que crea las filas de la tabla
 */
export const Row = React.memo((props:RowProps) => {

    const _onChange = (v:objectKV) => {
        if(props?.data?.id) props.clickSelect(props?.data?.id);
    };

    const getDisabled = React.useCallback(() => {
        if(props.getIsSelectable) return props.getIsSelectable(props.data);
        return false;
    },[props.data]);

    const style = React.useMemo(() => {
        if(props.rowStyleMaker){
            let temp = props.rowStyleMaker(props.data,props.index);
            if(temp && Object.keys(temp).length) return temp;
        }
        return;
    },[props.rowStyleMaker,JSON.stringify(props.data),props.index]);

    return (
        <TrBasic 
        className={`table__row table__row--body ${props.selected?"isSelected":""}`}
        style={style}
        >
            <React.Fragment>
                {props.showSelector?<TDTHBasic style={{width:25}}>
                    <div style={{width:25, height:30}}>
                        <InputCheck disabled={getDisabled()} value={props.selected} name="select" onChange={_onChange}/>
                    </div>
                </TDTHBasic> : null}
                <>
                {props.fields?.filter((item:confTable) => !item.hidden).sort((a:confTable,b:confTable) => ((a.order??0) - (b.order??0))).map((item:confTable, index:number) => <RowElement 
                                                                        factory={props.factory} 
                                                                        onClick={props.onClick} 
                                                                        field={item}
                                                                        data={props.data}
                                                                        key={item.id ?? index} 
                />)}
                </>
            </React.Fragment>
        </TrBasic>
    );
});

export const SelectAll = React.memo((props: {isSelectable:boolean,id?:Array<string | number>, onClick:(v:boolean)=> any}) => {

    const {value,setValue, bind} = useInputBoolean(false);

    React.useEffect(() => {
        props.onClick(value);
    },[value]);

    React.useEffect(() => {
        if(!props.id?.length && value) setValue(false);
    },[props.id]);

    return (props.isSelectable? <th style={{width:35}}>
    <div style={{width:25, marginTop:5}}>
        <InputCheck message="Seleccionar todos los registros de esta pagina" name="select" {...bind}/>
    </div>
</th>:null);
});

export const isSelected = (id?:number | string, ids?:Array<number | string>) => {
    if(!id || !ids?.length) return false;
    return ids.includes(id);
};

export const ListTable = React.memo((props: any) => {
    return props.data?.map? props.data?.map( (itemData:objectKV, indexData:number) => {
            return <Row 
            getIsSelectable={props.getIsSelectable} 
            selected={isSelected(itemData?.id, props.idSelected)} 
            showSelector={props.isSelectable} 
            clickSelect={props.clickSelect} 
            key={itemData.id ?? indexData} 
            factory={props.factory} 
            fields={props.fields} 
            data={itemData} 
            index={indexData} 
            onClick={props.clickRow ?? props.onClick}
            rowStyleMaker={props.rowStyleMaker}
            />
        }):<TrBasic/>;
});

export const Tbody = React.memo((props:objectKV) => {
    
    // const isDiferentObjects = (a:Array<objectKV>,b:Array<objectKV>) => {
    //     if(a.length !== b.length) return true;
    //     if(arrayIsContent(a,b)) return true;
    //     if(arrayIsContent(b,a)) return true;
    //     if(a.find((item:objectKV,index:number) => {
    //         return b[index]?.id !== item?.id;
    //     })) return true;
    //     return false;
    // }
    const isDiferentArrayInit = (a:Array<objectKV>,b:Array<objectKV>,count:number) => {
       if((a?.length??0) >= count && b.length < count) return true;
       if((a?.length??0) < b.length) return true;
       for (let i = 0; i < count; i++) {
           if(JSON.stringify(Array.isArray(a)?a[i]:{}) !== JSON.stringify(b[i])) return true;
       }
       return false;
    }

    
    // const isDiferentObjectsOrder = (a:Array<objectKV>,b:Array<objectKV>) => {
    //     if(a.find((item:objectKV,index:number) => {
    //         return !b[index]?.id || !item?.id || b[index]?.id !== item?.id;
    //     })) return true;
    //     return false;
    // }

    /**Retorna true si un valor de la a no se encuentra en b */
    // const arrayIsContent = (a:Array<objectKV>,b:Array<objectKV>) => !!a.find((item:objectKV) => {
    //         return !b.find((item_2:objectKV) => (item.id === item_2.id && JSON.stringify(item) === JSON.stringify(item_2)));
    // });

    const {tableContainerRef, data, ...all} = props;

    const [newData, setNewData] = React.useState<Array<objectKV>>([]);
    // const [flagNewData, setFlagNewData] = React.useState<boolean>(false);
    // const dataIsDiferent = React.useMemo(() => isDiferentObjects(newData,data ?? []),[newData,data]);

    const [ heightTable , setHeightTable ] = React.useState<number>(0);
    const [ heightContainerTable , setHeightContainerTable ] = React.useState<number>(0);

    const el = React.useRef(null);
    // const dataPrev = usePrevious(data);
    // const fieldsPrev = usePrevious(fields);
    const _elTableContainer:any = tableContainerRef?.current;
    const _elTable:any = el?.current;

    React.useEffect(() => {
        setHeightTable(_elTable?.getBoundingClientRect()?.height ?? 0);
    },[JSON.stringify(_elTable?.getBoundingClientRect())]);        
    
    React.useEffect(() => {
        setHeightContainerTable(_elTableContainer?.getBoundingClientRect()?.height ?? 0);
    },[JSON.stringify(_elTableContainer?.getBoundingClientRect())]);    

    React.useEffect(() => {   
        /** 
         * NOTA MUY MUY IMPORTANTE: por el tiempo que se tenia para este desarrollo la funcion de windowing (ver https://es.reactjs.org/docs/optimizing-performance.html)
         * no se termino completamente, ademas para cantidades de registros superiores a 30 puede aparecer bug casos como registros que no se renderizan 
         * se opto por no usar una biblioteca ya creada de virtualizacion debido a:
         * 1. Una politica mas que nada rumor de la empresa de granada de limitar el uso de bibliotecas.
         * 2. Aunque las bibliotecas eran recomendas por el mismo react, daban la impresion de ser muy basicas aun (esto es relativo al momento, debido a que ya podrian ser robustas y una buena opcion para implementar).
         * 3. El componente tabla ya estaba creado desde un principio lo que hacia inconveniete en terminos de tiempo adaptar una nueva bibioteca.
         * 4. Era algo retador y queria aprender a hacerlo por mi cuenta (Esta es extraoficial, solo tomar en cuenta las primeras tres)
         * */     
        let numReg = 30;
        if(isDiferentArrayInit(data,newData,numReg)){
            setNewData(data?.length?[...data.slice(0,numReg)]:[]);
        }
    },[data]);

    React.useEffect(() => {
        // console.log("here",heightTable , heightContainerTable);
        //Se valida si el tamaño de la tabla es igual al tamaño de la pantalla, si es menor entonces:
        if(heightTable < heightContainerTable){
            setNewData((_newData:Array<objectKV>) => {
                const _length = _newData.length;
                const temp = data?.slice(_length,_length+1);
                return temp?[..._newData,...temp]:_newData;
            });
        }
    },[JSON.stringify(newData)]);

    React.useEffect(() => {
        const averageHeight = heightTable/(newData.length || 1) || 1;
        if(_elTableContainer?.offsetHeight + _elTableContainer?.scrollTop >= (_elTableContainer?.scrollHeight-(averageHeight*2))){
            if(data?.length && newData.length < data?.length) {
                setNewData((_newData:Array<objectKV>) => {
                    const _length = _newData.length;
                    const temp = data.slice(_length,_length+3);
                    return temp?[..._newData,...temp]:_newData;
                });
            }
        }
    },[_elTableContainer?.offsetHeight , _elTableContainer?.scrollTop , _elTableContainer?.scrollHeight]);

    // React.useEffect(() => {
    //     console.log(newData)
    // },[newData]);

    return (
        <TBodyBasic ref={el} className="tbody">
            {props.children ?? <ListTable  data={newData} {...all}/>}
        </TBodyBasic>
    );
});

/**
 * @description este es el componente tabla que muestra.
 */
export const Table =  React.memo(React.forwardRef((props: TableProps,ref:any) => {
    /**
     * Es la referencia a los hijos Input de los filtros
     */
    const filtersRef =  React.useMemo(() => {
        return props.fields.map(() => React.createRef());
    },[props.fields])
    /**
     * Es la referencia a los hijos Input de los filtros
     */
    const containerTable = React.useRef(null);

    /**
     * Es la posicion del scroll, para mantener los headers fijos ne la parte superios de la tabla 
     */
    const [ positionTop, setPositionTop] = React.useState<number>();

    // React.useEffect(() => {
    //     console.log(filtersRef)
    //     // props.fields.map(() => React.createRef())
    // },[filtersRef]);

    const reducer = (state:objectKV,action:action) => {
        switch(action.type){
            case "ADD_FILTER_IN_TABLE":
                if(action.value) return Object.assign({},state,action.value);
                return state;
            case "RESET_FILTER_IN_TABLE":
                return {};
            default:
                throw new Error('Unexpected action');
        }
    };

    /**
     * Son los filtos de la tabla
     */
    const [ filters, setFilters] = React.useReducer(reducer,{});

    /**
     * @description maneja el click en una de las columnas 
     * @param tableField 
     * @param orderableField 
     */
    const _clickField = ( tableField:confTable,orderableField?: orderableField) => {
        let newOrderableField = order( tableField,orderableField);
        if(props.orderClick && newOrderableField) props.orderClick({value:newOrderableField,type:"SET_ORDER"});
    };

    // /**
    //  * @description maneja los cambios en los filtros
    //  * @param value 
    //  */
    // const _filterChange = (value?:objectKV) => {
    //     console.log({...filters,...value})
    //     if(value) setFilters({...filters,...value});
    // };

    /**
     * @description esta funcion resetea los filtros.
     */
    const resetFilters = () => {
        //HOOK_emit_filters_1 en este puento se llama el rest de cada input
        // y se retorna un objeto vacio.
        setFilters({type:"RESET_FILTER_IN_TABLE"});
        filtersRef.forEach((item:any) => item?.current?.reset());
        // setFilters({type:"RESET_FILTER_IN_TABLE"});
    };

    /**
     * Crea los funciones que se ejecutaran por referencia.
     */
    React.useImperativeHandle(ref, ()=>({
        resetFilters
    }));


    /**
     * reacioana a los cambios en los filtros y los envia al padre 
     */
    React.useEffect(() => {
        if (props.getFilters && props.fields.length) {
            // if(!Object.values(filters).filter((item:any) => !!item).length) return props.getFilters({ value: "RESET_ALL", type: "ADD_FILTERS_AND_FORMATTER" });
            props.getFilters({ value: filters, type: "ADD_FILTERS" });
        }
    },[filters]);

    /**
     * Reaciona al inicio del componete
     */
    React.useEffect(() => {
        props.fields.forEach((item:confTable) => {
            if(item.orderable){
                if(!item.id && !item.field) console.warn("Error: no se encuentra la propiedad field o id en un campo ordenable",item);
            }
        });
    }, []);

    const _onClick = (v:objectKV) => {
    }

    const _clickSelect = (v:string | number) => {
        if(props.valuesSelected) {
            if(props.idSelected?.length && props.idSelected.includes(v)){
                let _values = props.idSelected.filter((item:string | number) => item !== v);
                props.valuesSelected([..._values]);
            }
            else {
                props.valuesSelected([...(props.idSelected ?? []), v])
            }
        }
    }

    const _onClickSelectAll = (v:boolean) => {
        if(props.valuesSelected) {
            if(v && props.data.length) {
                // let ids = props.data.map((item:objectKV) => item && item.id).filter(Boolean);
                props.valuesSelected([Infinity]);
            }
            else {
                props.valuesSelected([]);
            }
        }
    };

    // React.useEffect(() => {
    //     console.log("filtres55",props.fields)
    //  },[JSON.stringify(props.fields)]);

    return (<div className="container-table" >
        {/* en este caso el valor es 18 ya que este es el numero en px del alto de la barra de carga  */}
        <div className={`load-indicator`} style={{height:props.loading?18:0}} >Cargando...</div>
        <div className="table"
        ref={containerTable}
        // onScroll={(e: objectKV) => setPositionTop( e.currentTarget.scrollTop )} 
        >
            <TableBasic className="table__body" >
                    <THeadBasic className="head-table" 
                    // style={{ transform: `translateY(${positionTop}px)` }}
                    >
                        <TrBasic className="table__row table__row--fixed">
                            <React.Fragment>
                            <SelectAll isSelectable={!!props.isSelectable} id={props.idSelected} onClick={_onClickSelectAll} />
                            {props.fields?.filter((item: confTable) => !item.hidden).sort((a: confTable, b: confTable) => ((a.order ?? 0) - (b.order ?? 0))).map((item: confTable, index: number) => (
                                <TDTHBasic
                                    className={`table__row--field`}
                                    style={{ width: item.width }}

                                    key={item.id ?? index}>
                                        <React.Fragment>
                                            <div
                                                className={`table__row--field__text 
                                            ${item.orderable ? "table__row--field--orderable" : ""} 
                                            ${destructor(item.field, filters) ? "table__row--field--isFiltered" : ""} 
                                            ${item.textAling === "RIGHT" ? "justify-content-right" : item.textAling === "CENTER" ? "justify-content-center" : ""}`}
                                                style={{ width: item.width }}
                                                onClick={() => _clickField(item, props.orderableField)}>
                                                <label className="label-text">{item.label}</label>
                                                {
                                                    ((props.orderableField?.field && props.orderableField?.field === item.field) || (props.orderableField?.id && props.orderableField?.id === item.id)) && item.orderable ?
                                                        <div className="arrow" style={{ transform: `rotate(${props.orderableField?.order === "ASC" ? "0deg" : "180deg"})` }}>
                                                            <i className={"icon-keyboard_arrow_down "} />
                                                        </div> : <div className="arrow" />
                                                }
                                            </div>
                                            {props.fields.filter((conf: confTable) => conf.filterable).length ?
                                                <InputFilters ref={filtersRef[index]} factoryFilters={props.factoryFilters} hidden={props.hiddenFilters} confField={item} onChange={setFilters} /> :
                                                null}
                                        </React.Fragment>
                                </TDTHBasic>))}                     
                            </React.Fragment>
                        </TrBasic>
                    </THeadBasic>
                    <Tbody 
                    {...props} 
                    tableContainerRef={containerTable} 
                    clickSelect={_clickSelect} 
                    onClick={_onClick} 
                    rowStyleMaker={props.rowStyleMaker}
                    />
            </TableBasic>
            {!props.data?.length && !props.children && !props.loading?<NoData />:""}
        </div>
    </div>
    );   
}));
