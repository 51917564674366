import React from "react";
import { action, objectKV, error } from "../../../utils/interface";

const GenaralStoreContext = React.createContext(undefined);

export const GenaralStoreProvider = (props:any) => {

    const reducer = (state:any, action:action) => {
        switch(action.type) {
            case "SET_DATA":
                if(typeof(action.value?.key) !== "string") return state;
               return Object.assign({}, state,{[action.value?.key]:action.value?.value});
            case "REMOVE_DATA":
                if(typeof(action.value) !== "string") return state;
                let {[action.value]:remove,...all} = state??{};
                return Object.assign({}, all);
            default: 
                throw new Error();
        }
    };

    let init = {
    }
    const [state , dispatch] = React.useReducer(reducer, {});

    const getData = React.useCallback((key:string) => {
        if(state[key] !== undefined) return state[key];
        return null;
    },[state]);

    let value = React.useMemo(()=>({state,getData , setData:dispatch}), [state , dispatch,getData]);
    return <GenaralStoreContext.Provider value={value} {...props} />
};

export const useGeneralStoreContext = ():{error?:error, state:objectKV,getData:(v:string) => any, setData:(v:action) => any} => {
    const context = React.useContext(GenaralStoreContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};