import React from "react";
import "./NotFound.scss";

const NoData = () => {
    return (
        <div className="no-data content-center">
            <div className="no-data__face">=(</div>
            <div className="no-data__text">No hay datos</div>
        </div>
        )
}

export default NoData;