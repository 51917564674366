import React, { useState, useEffect, useCallback } from "react";
import { objectKV } from "./../../utils/interface";

/**
 * @description este es un hook que se encarga de los inputs
 * @param initialValue es el valor con el cual iniciara 
 * @returns value es el valor del input
 * @returns setValue es una funcion que recibe los cambios en el input
 * @returns reset permite resetear el valor del input
 * @returns valueKV  retorna un object key value, util en los foemulario
 * @returns bind son los valores utiles al input
 * 
 */
export const useInput = (initialValue: string = "", delay?:number, name?:string) => {
  const [value, setValue] = useState<string>();
  const [valueKV, setValueKV] = useState<objectKV>();
  const [_valueV, setValueVTemp] = useState<string >();
  const [_valueK, setValueKTemp] = useState<string>();
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    let id:any;
    setValue(_valueV ?? "");
    const assing = () => {
      if( _valueK ) setValueKV({[_valueK]:_valueV});
    };
    if ( _valueK ) {
      if( _valueV === undefined) setValueKV({});
      else {
        if(delay !== undefined) id = setTimeout(assing ,delay);
        else assing();
      }
    }
    return () => clearTimeout(id);
  }, [ _valueV]);

  useEffect(() => {
    if(initialValue) {
      setValue(initialValue);
      if(name) setValueKV({[name]:initialValue});
    }
  },[]);

  const reset = () => {
    setValue(undefined);
    setValueVTemp(undefined);
  };

  const onChange = useCallback((event:any) => {
      setValueKTemp(event?.target?.name ?? "value");
      setValueVTemp(event.target.value);
  },[]);
  
  return {
    value,
    setValue,
    valueKV,
    focused,
    reset,
    setValueKV:(key:string, value:any) => {
      setValueKV({[key]:value});
      setValue(value);
    },
    bind: {
      value,
      onFocus:() => setFocused(true),
      onBlur: () => setFocused(false),
      onChange
    }
  };
};

export const useInputBoolean = (initialValue: boolean = false) => {
  const [value, setValue] = useState<boolean>(initialValue);
  const [valueKV, setValueKV] = useState<objectKV>();

  const _setValueKV = (key:string, value:boolean) => {
    setValueKV({[key]:value});
    setValue(value);
  }

  return {
    value,
    setValue,
    valueKV,
    setValueKV:_setValueKV,
    bind: {
      value,
      onChange: (event:any) => {
        _setValueKV(event?.target?.name ?? "value",event.target.value);
      }
    }
  };
};

/**
 * @description este es un hook que se encarga de los inputs
 * @param initialValue es el valor con el cual iniciara 
 * @returns value es el valor del input
 * @returns setValue es una funcion que recibe los cambios en el input
 * @returns reset permite resetear el valor del input
 * @returns valueKV  retorna un object key value, util en los foemulario
 * @returns bind son los valores utiles al input
 * 
 */
export const useInputMultiSelect = (initialValue?: Array<string | number>, name?: string) => {
  const [value, setValue] = React.useState<Array<string | number>>(initialValue ?? []);
  const [valueKV, setValueKV] = useState<objectKV>();
  const [focused, setFocused] = useState<boolean>(false);
  

  const onChange = useCallback((event: any) => {
    setValue(event?.target?.value ?? "value");
    setValueKV({ [event.target.name]: event.target.value });
  }, []);
  
  const reset = () => {
    setValue([]);
    setValueKV({});
  };

  return {
    value,
    setValue,
    valueKV,
    focused,
    reset,
    setValueKV:(key:string, value:any) => {
      setValueKV({[key]:value});
      setValue(value);
    },
    bind: {
      value,
      onFocus:() => setFocused(true),
      onBlur: () => setFocused(false),
      onChange
    }
  };

 }