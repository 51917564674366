import React from "react";
import { error, objectKV } from "./../../../utils/interface";
import "./Input.scss";

interface InputFloatProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;
    /**
     * reperesenta el ancho del componente por defecto es 200
     */
    width?:number;
    /** */
    [v:string]:any;

}

const InputFloat = (props: InputFloatProps) => {

    const {label,error,focused,...bind} = props;

    const { value, onChange, ...allRest} = bind??{};

    const [_value, setValue ] = React.useState<string>();

    const el = React.useRef(null);

    let [_focused, setFocused ] = React.useState<boolean>(false);

    const _onFocus = () => {
        let _el:any = el?.current;
        setFocused(true);
        if(focused) focused(true);
        if(_el?.focus) _el?.focus();
    };

    const _onBlur = () => {
        let _el:any = el?.current;
        setFocused(false);
        if(focused) focused(false);
        if(_el?.blur) _el?.blur();
    };

    /**
     * validamos que no se pueda ingresar el caracter de la comilla, para disminuir la posibilidad de ataque de inyeccion
     * @param e 
     */
    const _onKeyPress = (e:objectKV) => {
        if((e.key+"").match(/'/g)) {
            e.preventDefault();
        }
    };
    
    const _onChange = React.useCallback((e:any) => {
        setValue(e?.target?.value);
        onChange({target:{value:e?.target?.value,name:e?.target?.name}});
    },[]);

    React.useEffect(() => {
        if(value !== _value) {
            if(!value) setValue("");
            else setValue(value);
        }
    },[value]);
    
    return (
        <div className="container-float" style={{ width: props.width ?? "180px"}}>
            {props.label? <div onClick={_onFocus} className={`label ${_focused || bind?.value ? "label__focus": ""}`}
            style={{color: props.error?.error? "#de3838": _focused || bind?.value? "#4d90fe": "#c3c2c3"}}
            >{props.label}</div>: null}

            <input 
            ref={el} 
            value={_value}
            onChangeCapture={_onChange}
            onKeyPress={_onKeyPress} 
            onFocusCapture={_onFocus} 
            onBlurCapture={_onBlur} 
            className="container-float__input" 
            {...allRest} 
            style={{borderBottomColor:props.error?.error? "#de3838": _focused || bind?.value? "#4d90fe": "#c3c2c3"}}/>
            {/*  */}
            {props.error?.error? <div className="error">{props.error.message}</div>: null}
        </div>
    );
}

export default InputFloat;