import { confTable, excelField, objectKV } from "../../utils/interface";
import { getEta, getPercentage } from "./Home.factory";


export const fields:Array<confTable> = [
    {
        field:"documentocliente",
        label:"Doc. Cliente",
        type:"TEXT",
        word:true,
        path:"documentocliente",
        orderable:true,
        filterable:true,
        textAling:"CENTER"
    },
    {
        field:"origen",
        label:"Origen",
        path:"origen",
        orderable:true,
        filterable:true
    },
    {
        field:"destino",
        label:"Destino",
        path:"destino",
        orderable:true,
        filterable:true
    },
    {
        field:"placa",
        label:"Placa",
        type:"TEXT",
        word:true,
        textAling:"CENTER",
        path:"placa",
        orderable:true,
        filterable:true
    },  
    {
        field:"nombreconductor",
        label:"Conductor",
        type:"TEXT",
        word:true,
        textAling:"CENTER",
        path:"nombreconductor",
        useTooltip:true,
        width:100,
        orderable:true,
        filterable:true
    },
    {
        field:"documentoconductor",
        label:"Cédula",
        type:"TEXT",
        word:true,
        textAling:"CENTER",
        path:"documentoconductor",
        useTooltip:true,
        width:100,
        orderable:true,
        filterable:true
    },
    {
        field:"numerocontenedor",
        label:"No. cont.",
        type:"TEXT",
        word:true,
        textAling:"CENTER",
        path:"numerocontenedor",
        useTooltip:true,
        width:120,
        orderable:true,
        filterable:true
    },
    {
        field:"advance",
        label:"Avance Viaje",
        orderable:false,
        filterable:false
    },
    {
        field:"ETA",
        label:"ETA",
        path:"eta",
        orderable:false,
        filterable:false,
        textAling:"RIGHT"
    },  
    {
        field:"ultimoreporte",
        label:"Últ. Reporte",
        type:"TEXT",
        path:"ultimoreporte",
        orderable:true,
        filterable:true,
        width:300
    },
    {
        field:"lugar",
        label:"Lugar Reporte",
        type:"TEXT",
        path:"lugar",
        orderable:true,
        filterable:true,
        width:100
    },
    {
        field:"fechahoraultimoevento",
        label:"Fecha-Hora",
        type:"DATE-TIME",
        path:"fechahoraultimoevento",
        orderable:true,
        filterable:false,
        textAling:"RIGHT"
    },
    {
        field:"idsysestado",
        label:"Estado mfto",
        orderable:true,
        filterable:true,
        textAling:"CENTER"
    },
    {
        field:"manifiesto",
        label:"No. manifiesto",
        orderable:true,
        filterable:true,
        type:"TEXT",
        word:true,
        path:"manifiesto",
        textAling:"CENTER"
    },
    {
        field:"idsysestadorem",
        label:"Estado rem",
        orderable:true,
        filterable:true,
        textAling:"CENTER"
    },
    {
        field:"numeroremesa",
        label:"No. remesa",
        type:"TEXT",
        word:true,
        path:"numeroremesa",
        orderable:true,
        filterable:true
    },
    {
        field:"id",
        label:"No. viaje",
        type:"TEXT",
        word:true,
        width:100,
        path:"id",
        orderable:true,
        filterable:true
    }
];


export const getSysEstados = `
query getSysEstados {
    sysEstados{
        data{
          id
          iconname
          descripcion
        }
      }
}
`;

export const queryDataTravel = `
query getTravels ($filters: [FilterInput],$orders: [OrderInput],$pagination: PaginationInput,$idcliente:Int) {  
    viajesClientesInfo(filters:$filters,orders:$orders,pagination:$pagination,idcliente:$idcliente) {
        data {
          id
          idviaje
          documentocliente
          origen
          destino
          idsysestado
          idsysestadorem
          placa
          estado
          ultimoreporte
          numeroremesa
          fechahoraultimoevento
          lugar
          fechahoradescarguesalida
          fechahoracitadescargue
          fechahoracarguesalida
          empresatransportadora
          idproximopuestocontrol
          numerocontenedor
          documentoconductor
          nombreconductor
          manifiesto
          planviaje {
            id
            puestocontro
            idpuestocontrol
            distancia
            duracion
            orden
          }
        }
        totalreg
    }
}
`;

export const fieldsTraza:Array<confTable> = [
    {
        field:"fotos",
        label:"Fotos",
        orderable:false,
        filterable:false
    },
    {
        field:"fechahora",
        label:"Fecha-Hora",
        type:"DATE-TIME",
        path:"fechahora",
        orderable:false,
        filterable:false
    },
    {
        field:"Lugar",
        label:"lugar",
        type:"TEXT",
        path:"lugar",
        orderable:false,
        filterable:false,
        width:200
    },
    {
        field:"reporte",
        label:"Reporte",
        type:"TEXT",
        orderable:false,
        filterable:false,
        concatToken:" - ",
        multiPath:["puestocontrol","sysevento","evento","novedad"]
    }
];


export const queryTravel = `
query getTravel($id:Int!){
    viajeClienteInfo(id:$id){
        id
        estado
        ruta
        numeroremesa
        placa
        fotoconductor
        vehiculofoto
        observaciones
        numerocontenedor
        marca
        modelo
        color
        tipo
        trailerplaca
        nombreconductor
        documentoconductor
        telefonoconductor
        trazabilidad {
            id
            novedad
            lugar
            puestocontrol
            sysevento
            evento
            numberdocs
            fechahora
          }
    }
}
`;

export const fieldExcelTraza:Array<excelField> = [
    {
        id:1,
        value:"Fecha-Hora",
        getData: (data:objectKV) => {
            return +data.fechahora?new Date(+data.fechahora).toLocaleString():"";
        }
    },
    {
        value:"Lugar",
        id:2,
        path:"lugar"
    },
    {
        id:3,
        value:"Reporte",
        getData: (data:objectKV) => {
            let {puestocontrol,sysevento,evento,novedad} = data??{};
            return [puestocontrol,sysevento,evento,novedad].filter((i:any) => !!i).join(" - ");
        }
    }
];

export const fieldExcel:Array<excelField> = [
    {
        id:1,
        value:"Doc. Cliente",
        path:"documentocliente"
    },
    {
        id:2,
        value:"Remesa",
        path:"numeroremesa"
    },
    {
        id:3,
        path:"origen",
        value:"Origen"
    },
    {
        id:4,
        value:"Destino",
        path:"destino",
    },
    {
        id:5,
        value:"Estado",
        path:"estado"
    },
    {
        id:6,
        value:"Avance Viaje %",
        getData:(data:objectKV)=> {
            if(data?.planviaje?.length)return getPercentage(data).toString();
            return "";
        }
    },
    {
        id:7,
        value:"ETA",
        getData:(data) => {
            if (data?.planviaje?.length) {
                let eta = getEta(data);
                return eta?new Date(eta).toLocaleString():"";
            }
            return "";
        }
    },
    {
        id:8,
        value:"Placa",
        path:"placa",
    },
    {
        id:9,
        value:"Ult. Reporte",
        path:"ultimoreporte",
    },
    {
        id:10,
        value:"Lugar Reporte",
        path:"lugar",
    },
    {
        id:11,
        value:"Hora Cita",
        getData: (data:objectKV) => {
            return +data.fechahoracitadescargue?new Date(+data.fechahoracitadescargue).toLocaleString():"";
        }
    },
    {
        id:12,
        value:"Empresa Transportadora",
        path:"empresatransportadora",
    }
];


export const getSignedImage = `
query getSignedImage($id:Int){
    getSignedURLReport(idreporte: $id) {
        id
        key
        url
      }
}
`;