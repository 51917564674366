import { formatterDateTime } from "../Componets/Basic/Text/DateView";
import { objectKV } from "./interface";
import { getStoreInfo } from "./store";
import JSZip from "jszip";


/**
 * @description odtiene un valor de un objeto.
 * @param path es la ruta del valor que deceamos odtener
 * @param data es el objeto del cual se odtendran el valor.
 */
export const destructor = (path:string | Array <string>, data: objectKV) => {
    let newPath = path
    if(typeof(newPath) === "string"){
        newPath = newPath.split(".");
    }
  return newPath.reduce((accumulator: any, current: string) => accumulator && accumulator[current], data);
};

/**
 * @description est afuncion obtene una direcion de la geolocalizacion
 * @param lat 
 * @param lng 
 * @deprecated
 */
export const getAdress = async (lat:number, lng:number) => {
  try {
    let res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAPSKEY}`).then((res:any) =>res.json());
    if(!res?.results?.length) return null;
    return res.results[0].formatted_address;
  } catch (error) {
    return null;
  }
};

/**
 * @description esta funcion obtiene la geolocalizacion de una direcion 
 * @param adress 
 * @deprecated
 */
export const getGeolocation = async (adress?:string) => {
  if(!adress) return null;
  let tempAdress = adress.replace("#","");
  try {
    let _adress:any = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${tempAdress}&key=${process.env.REACT_APP_MAPSKEY}`).then((res:any) => res.json());
    if(!_adress?.results?.length) return null;
    return {latitud:_adress.results[0].geometry.location.lat,longitud:_adress.results[0].geometry.location.lng};     
  } catch (error) {
    return null;
  }
};

export const parseJson = (v: any) => {
  try {
    return JSON.parse(v);
  } catch (error) {
    return null;
  }
};
 
export const stringifyJson = (v: any) => {
  try {
    return JSON.stringify(v);
  } catch (error) {
    return null;
  }
};
 
export const getRangeDays = (v: number) => {
  try {
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate() - v);
    return tempDate;
  } catch (error) {
    return null;
  }
};

export const getRangeMonths = (v: number) => {
  try {
    let tempDate = new Date();
    tempDate.setMonth(tempDate.getMonth() - v);
    return tempDate;
  } catch (error) {
    return null;
  }
};

export const dateToString = (v?: Date| null) => {
  try {
    let dateTemp = v;
    if (!v?.getTime) return "";
    return `${v.getDate()}-${v?.getMonth()+1}-${v.getFullYear()}`;
  } catch (error) {
    return "";
  }
};

/**
 * 
 * @param v DD-MM-YYYY
 * @returns 
 */
export const stringToDate = (v: string) => {
  try {
    let dateTemp:any = v.split("-");
    dateTemp = new Date(+dateTemp[2],(+dateTemp[1])-1,+dateTemp[0],0,0,0);
    if (Number.isNaN(dateTemp.getDate())) return null;
    return dateTemp;
  } catch (error) {
    return null;
  }
};

const sysEvents = `
sysEventos {
  data {
    id
    iconname
    descripcion
  }
}
`;

const sysState = `
sysEstados{
  data{
    id
    iconname
    descripcion
  }
}
`;

/**
 * @description retorna un DocumentNode con el query para realizar la consulta de los syseventos
 */
export const queryUtils = `
query utils{
  ${sysEvents}
  ${sysState}
}
`;

export const getSysEvents = `
query getSysEvents{
  ${sysEvents}
}
`;

export const getSysState = `
query getSysState{
  ${sysState}
}
`;

export const renameDocuments = (v:Array<any>) => {
  let session = getStoreInfo();
  let files = Array.from(v).map((item:any,index:number) => {
    return new File([item], `${session.idcompany}/${new Date().getTime()}-${session.id}-${index}-${item.name}`,{
        type: item.type,
    });;
  })
  return files;
};

/** 
 * @description en el servidor hay un query que permite odtener la hora, esta funcion hace el llamado de este query
*/
export const getDateServer = async (local?:boolean) => {
  let key = " __HPC";
  let token = "##";
  if(local) return `${new Date().toString()}${token}${key}`;
  let session = getStoreInfo();
  if(!process.env.REACT_APP_ID || !session.idcompany || !session.token) throw new Error("No se puede obtener la hora");
  let headers = new Headers();
  headers.append("Authorization",session.token);
  headers.append("idempresa",session.idcompany.toString());
  headers.append("idapp",process.env.REACT_APP_ID);
  headers.append("content-type","application/json");
  const res = await fetch(`${process.env.REACT_APP_URL}/graphql`,{
    headers,
    method:"POST",
    body:JSON.stringify({
      query:"{ getDate }"
    })
  })
  .then((res:any) => res.json())
  .catch(() => new Error("No se puede obtener la hora"));
  return res?.data?.getDate??`${new Date().toString()}${token}${key}`;
};

export const concatTime = (_date:string, _time:string) => {
  let newDate = new Date(`${_date} ${_time}`);
  return formatterTimeZoneDate(newDate.getTime()).time;
};

export const reportFormatter = async (v:objectKV) => {
  let { fecha, hora, imagenes, ...all} = v;
  let images = renameDocuments(imagenes);
  let report = Object.assign({}
    ,all
    ,{imagenes:images.map((item:File) => item.name)}
    ,{fechahora:concatTime(fecha,hora)});
  return {images,report};
}; 

/**
 * @description valida que una fecha no este fuera de un limite
 * @param time HH:MM
 * @param date AAAA/MM/DD
 * @param max number
 * @param min number
 */
export const validatorTimeDate = (time:string, date:string,max:number | null, min:number | null) => {
  min = Number.isNaN(min)?0:min;
  max = Number.isNaN(max)?0:max;
  let temptime = time.split(":").map((i:string)=>+i);
  let tempDate = date.split("/").map((i:string)=>+i);
  if(tempDate.length < 3 || temptime.length < 2) return "Invalid";
  let dateReported = new Date(tempDate[0],tempDate[1]-1,tempDate[2],temptime[0],temptime[1],0).getTime();
  if(dateReported < (min??0)) return "Fecha invalida, menor al último reporte";
  if(dateReported > ((max??999999999999999)+30000)) return "Fecha invalida, mayor a la hora actual";
  return "";
};

/**
 * @description permite formatar a un valor de colombia la fecha, tener mucho cuidado si la fecha esta dada por un numeric
 * y es un valor que proviene de un api, el valor time que este retorna puede ser errado,
 * el timeCurrentZone devuelve un valor con el offset aggregado, es decir altera la fecha y la hora para que se adapten a la zona del equipo, esta es util 
 * para dates que proviene de api's.
 * @param date 
 */
export const formatterTimeZoneDate = (date:number|string) => {
  let timeZone = "America/Bogota";
  let offsetTimeZone = -5;
  let diferenceOffsetTime:number|null = new Date().getTimezoneOffset()+(offsetTimeZone*60);
  diferenceOffsetTime = Number.isNaN(diferenceOffsetTime)?null:diferenceOffsetTime*60*1000;
  let time = diferenceOffsetTime === null?null:new Date(date).getTime()-diferenceOffsetTime;
  let timeCurrentZone = diferenceOffsetTime === null?null:new Date(date).getTime()+diferenceOffsetTime;
  return {
    dateString:new Date(date).toLocaleString("en-US", {timeZone}),
    time,
    timeCurrentZone
  };
};

/**
 * Esta funcion permite remover el accento de una string, tambien altera las ñ
 */
export const removeAccent = (str:string) => {
  return (str??"").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const geoToAdrs = async (lat?:number,lng?:number) => {
  if(!lat || !lng) return null;
  let _lat = +lat,_lng = +lng;
  if(!_lat || !_lng) return null;
  let uri = `http://api.positionstacyyk.com/v1/reverse?access_key=${process.env.REACT_APP_MAPS_KEY}&query=${_lat},${_lng}`;
  let res = await fetch(uri).then(_res => _res.json()).catch(err => ({_error:true}));
  if(res?._error) return null;
  let place = Array.isArray(res?.data)?res.data.sort((a:any,b:any) => a.distance-b.distance)[0]:null;
  return place;
};

export const createReportMail = (data:any,fields:any) => {
  const {trazability,container,docClient,origin,destination,carId,company, client, travelId,remittancesId,travelState,remittancesState} = data;
    return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Mail</title>
</head>
<body style="font-family: Arial, Helvetica, sans-serif;color:#7e7e7e;">
    <table align="center" style="width: 100%;background: rgb(255, 255, 255);">
        <tr style="width: 100%;">
            <td style="width: 100%;padding-left: 30px;padding-right: 30px">
                <div style="font-size: 24px;font-weight: bold;">
                  ${company??""}
                </div>
                <hr style=" border: 4px solid #0000004f;border-bottom: 0px;">
            </td>
        </tr>
        <tr style="width: 100%;">
            <td style="width: 100%;padding-left: 30px;padding-right: 30px">
                <table style="width: 100%;">
                    <tr style="width: 100%; font-size: 22px; font-weight: 600;">
                        <td style="width: 50%;">${client??""}</td>
                        <td style="width: 50%;" align="right">Placa:${carId??""}</td>
                    </tr>
                    <tr>
                        <td>Fecha: ${formatterDateTime(new Date().getTime())}</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr style="width: 200%;background-color:#e6e6e6 ;">
            <td style="width: 100%;">
              <table style="width: 100%;padding-bottom: 15px;" >
                <tr style="width: 100%;">
                  ${
                    (fields??[]).map((item:any,index:any,arr:Array<any>) => {
                      return `<td style="width: 33%;padding-left: 30px;padding-top: 12px;"><label style="font-weight: 700;">${item.label}</label>${data[item.path]??""}</td>
                    ${(((index+1)%3)===0)&&index+1 !== arr?.length?`</tr><tr style="width: 100%;">`:""}`
                    }).join("")
                  }
                </tr>
              </table>
            </td>
        </tr>
        
        <tr style="width: 100%;">
            <td style="width: 100%;padding-left: 30px;padding-right: 30px">
                <table style="border: 1px solid #9d9d9d;border-collapse: collapse;">
                    <tr style="border: 1px solid #9d9d9d;">
                        <th style="border: 1px solid #9d9d9d;">Tipo</th>
                        <th style="border: 1px solid #9d9d9d;">Lugar Reportado</th>
                        <th style="border: 1px solid #9d9d9d;">Reporte</th>
                        <th style="border: 1px solid #9d9d9d;">Fecha hora</th>
                    </tr>
                    ${trazability?.map((item:any) => {
                      return `
                      <tr style="border: 1px solid #9d9d9d;justify-content: center;text-align: center;align-items: center;width: 100%;">
                          <td style="border: 1px solid #9d9d9d;">${(item?.sysevento??item?.evento)??""}</td>
                          <td style="border: 1px solid #9d9d9d;">${item?.lugar??""}</td>
                          <td style="border: 1px solid #9d9d9d;">${item?.novedad??""}</td>
                          <td style="border: 1px solid #9d9d9d;">${+item?.fechahora?formatterDateTime(+item?.fechahora):""}</td>
                      </tr>
                      `;
                    })?.join("")}
                </table>
            </td>
        </tr>
        <tr style="width: 100%;">
            <td style="width: 100%;">
              <div style="display: grid; width: 100%;justify-content: center;text-align: center;align-items: center;padding: 20px;">
                <div style="font-size: 12px;width: 100%;display: grid;text-align: center;justify-content: center;">CONTROL TRAFICO GESTOR DOCUMENTAL, SOFTWARE ELABORADO POR:</div>
                <div style="font-size: 12px;width: 100%;display: grid;text-align: center;justify-content: center; font-weight: 700;">GRANADA.IO SAS</div>
                <div style="font-size: 12px;width: 100%;display: grid;text-align: center;justify-content: center;">+(57)(4) 444-42-37  MOVIL 320 788 09 95</div>
                <div style="font-size: 12px;width: 100%;display: grid;text-align: center;justify-content: center;">info@granada.io</div>
                <div style="font-size: 12px;width: 100%;display: grid;text-align: center;justify-content: center;">
                ${new Date().getFullYear()}&#169;
                </div>
              </div>
            </td>
        </tr>

    </table>
    <div style="display: grid; width: 100%;justify-content: center;text-align: center;align-items: center;padding: 20px;">
    <b>Está información es automática NO responder</b>
    </div>
</body>
</html>
    `;
  };

export const downloadBlob = (data:any,name:any) => {
  let uri = URL.createObjectURL(data);
  let a = document.createElement('a');
  a.href=uri;
  a.download=name;
  a.click();
  a.remove();
  window.URL.revokeObjectURL(uri);
};

interface zipValue {
  path:string;
  data:any;
  name:string;
} 

export const createZip = async (data:Array<zipValue>) => {
  let dataTemp:any = {};
  let zip = new JSZip();
  data.forEach((item:zipValue) => {
    let file = {name:item.name,data:item.data}
    if(!Array.isArray(dataTemp[item.path])) return dataTemp[item.path] = [file];
    dataTemp[item.path].push(file);
  });
  Object.keys(dataTemp).forEach((item:any) => {
    let fol = zip.folder(item);
    if(Array.isArray(dataTemp[item])){
      dataTemp[item].forEach((item2:any) => {
        console.log(item2)
        fol?.file(item2.name,item2.data);
      });
    }
  });
  return await zip.generateAsync({type:"blob"});
};

//   select r.lugar, r.fechahora,r.novedad,r.created_at as fechacreacion,r.orden as consecutivo,s.descripcion as eventosistema,e.descripcion as evento 
// from viajes.reportes r
// left join viajes.syseventos s s.id = r.idsysevento
// left join viajes.eventos e e.id r.idevento
// where r.idviaje = 33755