import React from "react";
import { notification, action, error } from "../../../utils/interface";

interface notificationContext {
    notification:notification,
    id:number;
}


const NotificationContext = React.createContext(undefined);

export const NotificationProvider = (props:any) => {

    const [notification, setNotifications ] = React.useState<Array<notificationContext>>([]); 
   
    const addNotification = (v:notification) => {
        // console.log(v, notification)
        const getId = (_notification:Array<notificationContext>) => {
            let _ids = _notification.map((not:notificationContext) => {
                return not.id;
            });
            return Math.max(..._ids, 0);
        }
        
        setNotifications(notification => {
            let id = getId(notification);
            let _notification = [...notification, {notification:v, id:id+1}];
            setTimeout(() => setNotifications(notification => [...notification.filter((item:notificationContext) => item.id !== id+1 )]), v.delay ?? 5000);
            return [..._notification];
        });
    };
     React.useEffect(() => {
        //  console.log(notification)
     }, [notification]);

    let value = React.useMemo(()=>({addNotification, notification}), [addNotification, notification]);
    return <NotificationContext.Provider value={value} {...props} />
};

export const useNotificationContext = ():{addNotification:(v:notification) => any,notification: Array<notificationContext>, error?:error } => {
    const context = React.useContext(NotificationContext);
    const [error, setError ] = React.useState<action>();
    if(!context) setError({type:"ERROR", value:"No existe un contexto"});
    return Object.assign({},{error},context);
};