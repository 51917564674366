import React from "react";
import { error, objectKV } from "../../../utils/interface";
import "./Input.scss";

interface InputTextProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    /**
     * es el label que se mostrara en el input
     */
    upperCase?:boolean;
    label?:string;
    /** */
    [v:string]:any;
}
const InputText = React.memo((props: InputTextProps) => {

    const {label,error,focused,name, className,style,upperCase,...bind} = props;

    const { value, onChange, ...allRest} = bind??{};

    const [_value, setValue ] = React.useState<string>();

    const [focus, setFocus] = React.useState<boolean>(false);

    const _onFocus = () => {
        if(focused) focused(true);
        setFocus(true);
    };

    const _onBlur = () => {
        if(focused) focused(false);
        setFocus(false);
    };

    /**
     * validamos que no se pueda ingresar el caracter de la comilla, para disminuir la posibilidad de ataque de inyeccion
     * @param e 
     */
    const _onKeyPress = (e:objectKV) => {
        if((e.key+"").match(/'/g)) {
            e.preventDefault();
        }
    };

    const _onChange = React.useCallback((e:   any) => {
        let tempValue = upperCase && e?.target?.value?`${e?.target?.value}`.toUpperCase():e?.target?.value;
        setValue(tempValue);
        if(onChange)onChange({target:{value:tempValue,name:e?.target?.name}});
    },[]);

    React.useEffect(() => {
        if(value !== _value) {
            if(!value) setValue("");
            else setValue(value);
        }
    },[value]);

    return (
        <div className="container">
            {label?<label className={`text-conf ${focus?"label-focus":""} ${error?.error?"error":""}`}>{label}</label>:null}
            <input
            onKeyPress={_onKeyPress}
            onFocusCapture={_onFocus}
            onBlurCapture={_onBlur}
            autoComplete="off"
            value={_value}
            name={name}
            onChangeCapture={_onChange}
            className={`input ${className ?? ""}`}
            type="text"
            style={{...{borderColor:error?.error?"red":""},...style}}
            {...allRest}
            />
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
    );
});

export default InputText;
