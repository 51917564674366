import React from "react";
import { error, objectKV, confSelect } from "../../../utils/interface";
import usePortal from "./../../hook/portal-hook";
import { destructor, removeAccent } from "./../../../utils/utils";
import "./Input.scss";
import ReactDOM from "react-dom";
import TextView from "../Text/TextView";
import { usePrevious } from "../../hook/prevValue-hook";
import InputText from "./InputText";
import { useInput } from "../../hook/input-hook";

interface SelectTextProps {
    /** indica si hay algun error */
    error?:error;
    /** esta funcion se dispara cada cambio, y recibe true el evento es focus o false si es blur */
    focused?:(v:boolean) => any;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;

    /**
     * Esta es la configuracion del select
     */
    confSelect?:confSelect;

    /**
     * Esta es la configuracion del select
     */
    disabled?:boolean;
    /**
     * 
     */
    children?: JSX.Element[] | JSX.Element;
    /**
     * Esta es la informacion que se presentara en el el select
     */
    data?: Array<objectKV>;
    /**
     * 
     */
    style?: objectKV;
    /** */
    [v:string]:any;

}

/**
 * @description se obtiene el valor que se va amitir 
 */
const getValue = (data:objectKV,confSelect?:confSelect) => {
    if(confSelect?.pathEmit) return destructor(confSelect?.pathEmit,data);
    return "NA";
};

/**
 * @description esta funcion obtiene ls descripcion con las rutas de los datos
 */
const getDescription = (data:objectKV,confSelect?:confSelect) => {
    let value:Array<string> = [];
    if(confSelect?.formatterValue) return confSelect.formatterValue(data) ?? null;
    if(confSelect?.multiPathOption?.length) value = confSelect?.multiPathOption.map(item => destructor(item,data)); 
    if(confSelect?.pathOption) value = [destructor(confSelect?.pathOption,data),...value];
    return value.filter(Boolean).join(confSelect?.tokenJoin ?? " ") ?? null;
};

/**
 * @description este componente se encarga de presentar las opciones para el select
 * @param props 
 */
export const Option = (props:{ data:objectKV,value?: string | number,confSelect?:confSelect,assingValue:(v?:objectKV) => any}) => {

    const [description, setDescription ] = React.useState<string | null>();
    const [value, setValue ] = React.useState<string | null >();

   React.useEffect(()=>{
        setDescription(getDescription(props.data, props.confSelect));
        let _value = getValue(props.data, props.confSelect);
        setValue(_value);
        if(!props.confSelect?.multiPathOption && !props.confSelect?.pathOption){
            console.warn("Error, no se necuntra un multiPathOption o pathOption");
        }
        if(!props.confSelect?.pathEmit){
            console.warn("Error no se encuntra un pathEmit");
        }
    },[props.data, props.confSelect]);
    
    const emit = (v?: string | null ) => {
        if(props.assingValue)
            props.assingValue({value:v ?? description, description, className:props.data.className});
    }

    const _onClick = () => {
        emit(value);
    };

    return (<div className={`option ${props.confSelect?.textAling === "RIGHT"? "justify-content-right": props.confSelect?.textAling === "CENTER"? "justify-content-center":""}`} 
    onClick={_onClick}>{props.data.className? <i key={0} className={`${props.data.className} fill`}/>: ""}<TextView height={20} text={description ?? ""}/></div>);

};

interface dataOption2 {
    description?:string; 
    className?:string;
    value:string;
}

/**
 * @description este componente se encarga de presentar las opciones para el select
 * @param props 
 */
export const Option2 = (props:{ data?:dataOption2,value?: string | number,confSelect?:confSelect,assingValue:(v?:objectKV) => any}) => {

    // const [value, setValue ] = React.useState<string | null >();
    const { description, className,value } = props.data??{};
    
    const emit = (v?: string | null ) => {
        if(props.assingValue)
            props.assingValue({value:v ?? description, description, className:className});
    }

    const _onClick = () => {
        emit(value??"");
    };

    return (<div className={`option ${props.confSelect?.textAling === "RIGHT"? "justify-content-right": props.confSelect?.textAling === "CENTER"? "justify-content-center":""}`} 
    onClick={_onClick}>{className? <i key={0} className={`${className??""} fill`}/>: ""}<TextView height={20} text={description ?? ""}/></div>);

};


/**
 * @description este compenenete renderiza las opciones en el la pantalla 
 * @param props 
 */
export const OptionsView = (props:{
    children?:JSX.Element[] | JSX.Element,origin:any, 
    visible?:boolean, 
    clickOutSide?:(v?:any)=>any,
    width?:number
    }) => {

    const el = usePortal("select-root");    

    const [position, setPosition] = React.useState<{top:number,left:number, height:number, [v:string]:any}>();
    const [visible, setVisible] = React.useState<boolean>();
    const positionParent = props.origin?.getBoundingClientRect();

    React.useEffect(() => {
        setPosition(positionParent);
        // se usa esta forma de comparar ya que es un objeto.
    }, [JSON.stringify(positionParent)]);

    React.useEffect(() => {
        let idSetTimeOut:any;
        if(props.visible) {
            setVisible(props.visible);
        }
        else {
            // este elemento es para evitar que se desaparezca la ventana muy rapido 
            // de esta forma se ve agradable.
            idSetTimeOut = setTimeout(() => setVisible(props.visible), 100); 
        }
        return () => {
            if(idSetTimeOut) clearTimeout(idSetTimeOut);
        };
    }, [props.visible]);

    const blockEvent = React.useCallback((e:any) => {
        // este array indica los key de los evento que se bloquearan 
        let keyCode = [37,38,39,40];
        if(keyCode.find((item:number) => item === e.keyCode)){
            if(e.stopPropagation){
                e.preventDefault();
                e.stopPropagation();
            }
        } 
    },[])

    React.useEffect(() => {
        if(visible) {
            document.addEventListener("keydown",blockEvent);
        }
        else {
            document.removeEventListener("keydown",blockEvent);
        }
    }, [visible]);

    const _onClick = () => {
        if(props.clickOutSide)
            props.clickOutSide();
    };

    const calculateTop = (top?:number,height?:number) => {
        let tempTop = +(top ?? 0);
        let tempHeight =  +(height ?? 0);
        if( window.innerHeight - tempTop <= 220 ) return window.innerHeight - 220;
        return tempTop+tempHeight-30;
    };

    return visible? ReactDOM.createPortal(<div>
    <div 
    className="option-select" 
    style={{
        top: calculateTop(position?.top,position?.height), 
        left: position?.left ?? 0,
        height:props.visible?"auto":0,
        padding:props.visible?undefined:0,
        overflow:props.visible?undefined:"hidden",
        border:props.visible?"":"none",
        ...(props.width?{minWidth:props.width}:{}),
        width:props.visible?undefined:0
        }}>

        {props.children}
        
    </div>
    <div onClick={_onClick} className="background"/>
    </div>,el):null;
};



/**
 * @description este es el componente que se en carag del select 
 * @param props 
 */
export const  InputSelect = React.memo((props: SelectTextProps) => {

    const {label,error,focused,...bind} = props;
    const {value,onChange} = bind;
    const [focus, setFocus] = React.useState<boolean>(false);
    const [description, setDescription] = React.useState<string>();
    const [className, setClassName] = React.useState<string>();
    const el = React.useRef(null);

    React.useEffect(() => {
        if(value){
            let dataValue = props.data?.length && props.data?.find((item:objectKV) => {
                let _value = getValue(item,props.confSelect);
                return ""+_value === ""+value;
            });
            if(dataValue){
                if(dataValue.className) setClassName(dataValue.className);
                let _description = getDescription(dataValue, props.confSelect);
                setDescription(_description ?? "NA");
            }
        }
        if((description || className) && !value ){
            setDescription("");
            setClassName("");
            setFocus(false);
        }
    }, [value, props.data]);

    const _onFocus = () => {
        if(focused) focused(true);
        setFocus(true);
    };

    const _onBlur = () => {
        if(focused) focused(false);
        setFocus(false);
    };

    const _assingValue = (v?:objectKV) => {
        // console.log("this.v",v)
        if(v?.value !== undefined && onChange) onChange({target:{value:v.value,name:props.name ?? ""}});
        setFocus(false);
    };

    return (
        <div className="container">
            <input type="hidden" name={props.name ?? ""} value={value} />
            {label?<label className={`text-conf ${focus?"label-focus":""} ${error?.error?"error":""}`}>{label}</label>:null}
            <div 
            ref={el} onClick={!props.disabled? _onFocus:() => {}} 
            className={`select ${focus? "select--focus": ""} ${props.disabled? "select--disabled": ""}`}
            style={{...props.style, borderColor:error?.error?"red":""}}
            >
                <div className="select__value-container">
                    <div className={`option-selected ${props.confSelect?.textAling === "RIGHT"? "justify-content-right": props.confSelect?.textAling === "CENTER"? "justify-content-center":""}`} >{className? [<i key={0} className={className}/>,<label key={1} className="fill"/>]: ""}{description}</div>
                </div>
                {!props.disabled?
                <div className="select__icon-arrow" style={{transform:`rotate(${focus?"180deg":"0deg"})`}} >
                    <i className="icon-arrow_drop_down"/>
                </div>:
                null
                }
            </div>
            <OptionsView width={props.style?.width} clickOutSide={_onBlur} visible={focus} origin={el.current} >
                {[  
                // <InputText key={"textInput"} style={{border:"none"}}/>,
                    <Option  key={0} data={{}} value={""} confSelect={props.confSelect} assingValue={_assingValue}/>,
                    ...(props.data?.map((item:objectKV,index:number) => <Option key={index+1} data={item} value={value} confSelect={props.confSelect} assingValue={_assingValue}/>)?? [])]}
            </OptionsView>
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
    );
});


/**
 * @description este es el componente que se encarga del select2 
 * @param props 
 */
export const  InputSelect2 = React.memo((props: SelectTextProps) => {

    const nameInput = "inputSelect2";

    const {label,error,focused,...bind} = props;
    const {value,onChange} = bind;
    const [focus, setFocus] = React.useState<boolean>(false);
    const [description, setDescription] = React.useState<string>();
    const [className, setClassName] = React.useState<string>();
    const [ data, setData ] = React.useState<Array<dataOption2>>([]);
    const [ allData, setAllData ] = React.useState<Array<dataOption2>>([]);
    const el = React.useRef(null);
    const prevData = usePrevious(props.data);
    const {setValueKV,valueKV,bind:bindInput,reset} = useInput("",300,nameInput);

    React.useEffect(() => {
        if(value){
            let dataValue = props.data?.length && props.data?.find((item:objectKV) => {
                let _value = getValue(item,props.confSelect);
                return ""+_value === ""+value;
            });
            if(dataValue){
                if(dataValue.className) setClassName(dataValue.className);
                let _description = getDescription(dataValue, props.confSelect);
                setDescription(_description ?? "NA");
            }
        }
        if((description || className) && !value ){
            setDescription("");
            setClassName("");
            setFocus(false);
        }
        if(JSON.stringify(props.data) !== JSON.stringify(prevData)){
            if(!props.confSelect?.multiPathOption && !props.confSelect?.pathOption && !props.confSelect?.formatterValue){
                console.warn("Error, no se necuntra un multiPathOption o pathOption");
                return;
            }
            if(!props.confSelect?.pathEmit){
                console.warn("Error no se encuntra un pathEmit");
                return;
            }
            let _tempData:Array<dataOption2> = !props.data?.length?[]:props.data.map((item:any) => {
                let _description = getDescription(item, props.confSelect);
                let _value = getValue(item, props.confSelect);
                let temp:dataOption2 =  {value:_value,description:_description??"",className:item?.className};
                return temp;
            });
            setAllData(_tempData);
            setData(_tempData);
        }
    }, [value, props.data]);

    React.useEffect(() => {
        if(JSON.stringify(props.data) !== JSON.stringify(prevData)){
            if(!props.confSelect?.multiPathOption && !props.confSelect?.pathOption && !props.confSelect?.formatterValue){
                console.warn("Error, no se necuntra un multiPathOption o pathOption");
                return;
            }
            if(!props.confSelect?.pathEmit){
                console.warn("Error no se encuntra un pathEmit");
                return;
            }
            let _tempData:Array<dataOption2> = !props.data?.length?[]:props.data.map((item:any) => {
                let _description = getDescription(item, props.confSelect);
                let _value = getValue(item, props.confSelect);
                let temp:dataOption2 =  {value:_value,description:_description??"",className:item?.className};
                return temp;
            });
            setAllData(_tempData);
            setData(_tempData);
        }
    }, [JSON.stringify(props.data)]);

    React.useEffect(() => {
        let valueFiltered = valueKV&&valueKV[nameInput];
        if(!valueFiltered) setData([...allData]);
        let newData = allData.filter((item:dataOption2) => {
            return removeAccent(item?.description??"")
            .toLocaleLowerCase()
            .includes(removeAccent(valueFiltered)
            .toLocaleLowerCase());
        });
        setData([...newData]);
    },[valueKV]);

    const _onFocus = () => {
        if(focused) focused(true);
        setFocus(true);
    };

    const _onBlur = () => {
        if(focused) focused(false);
        setFocus(false);
    };

    const _assingValue = (v?:objectKV) => {
        // console.log("this.v",v)
        if(v?.value !== undefined && onChange) onChange({target:{value:v.value,name:props.name ?? ""}});
        setFocus(false);
    };

    return (
    <div className="container">
        <input type="hidden" name={props.name ?? ""} value={value} />
        {label?<label className={`text-conf ${focus?"label-focus":""} ${error?.error?"error":""}`}>{label}</label>:null}
        <div 
        ref={el} onClick={!props.disabled? _onFocus:() => {}} 
        className={`select ${focus? "select--focus": ""} ${props.disabled? "select--disabled": ""}`}
        style={{...props.style, borderColor:error?.error?"red":""}}
        >
            <div className="select__value-container">
                <div className={`option-selected ${props.confSelect?.textAling === "RIGHT"? "justify-content-right": props.confSelect?.textAling === "CENTER"? "justify-content-center":""}`} >{className? [<i key={0} className={className}/>,<label key={1} className="fill"/>]: ""}{description}</div>
            </div>
            {!props.disabled?
            <div className="select__icon-arrow" style={{transform:`rotate(${focus?"180deg":"0deg"})`}} >
                <i className="icon-arrow_drop_down"/>
            </div>:
            null
            }
        </div>
        <OptionsView width={props.style?.width} clickOutSide={_onBlur} visible={focus} origin={el.current} >
            {[  
            // <InputText key={"textInput"} style={{border:"none"}}/>,
                <InputText className="input-select2" name={nameInput} placeholder="Search..." {...bindInput} />,
                <Option2  key={0} value={""} confSelect={props.confSelect} assingValue={_assingValue}/>,
                ...(data.map((item:dataOption2,index:number) => <Option2 key={`${value}_${index+1}`} data={item} assingValue={_assingValue}/>)?? [])]}
        </OptionsView>
        {error?.error?<label className="text-conf error">{error.message}</label>:null}
    </div>
    );
});
