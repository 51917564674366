import React from 'react'
import InputText from '../../Componets/Basic/Input/InputText';
import { InputSelect } from '../../Componets/Basic/Input/InputSelect';
import { confTable, objectKV, confMap } from '../../utils/interface';
import { useLazyQueryToSever, useQueryToSever } from '../../Componets/hook/query-hook';
import { getSignedImage, getSysEstados } from './Home.conf';
import Icon from '../../Componets/Basic/Icon/Icon.tooltip';
import TextView from '../../Componets/Basic/Text/TextView';
import { destructor } from '../../utils/utils';
import "./Home.scss";
import { DateViwer } from '../../Componets/Basic/Text/DateView';
import { Modal } from '../../Componets/Basic/Modal/Modal';
import { GalleryAbsolute, getImage } from '../../Componets/Basic/Gallery/Gallery';
import { useGeneralStore } from '../../Componets/hook/general-store-hook';


export interface factorFilter {
    /**
     * Esta es la configuracion de la columna de la tabla
     */
    confField:confTable;

    /** Estos son los campos que manejan los datos */
    bind:{
        value:any;
        onChange:(v:any) => any;
        [v:string]:any;
    };
}

export const getPercentage = (data:objectKV) => {
    let temp = [...(data?.planviaje??[])].sort((a:objectKV,b:objectKV) => +(a.orden ?? 0) - +(b.orden ?? 0));
    let distanceTraveled = 0;
    let distances = temp.reduce((prev:number, curr:objectKV) => {
        if(data.idproximopuestocontrol === curr.idpuestocontrol) distanceTraveled = prev;
        if(+curr.distancia) return prev + curr.distancia;
        return prev;
    },0);
    if(!data.idproximopuestocontrol) {
        if(data.idsysestado === 6) distanceTraveled = 0;
        else distanceTraveled = distances*(data.idsysestado === 7?1:0.99);
    }
    return Math.round((distanceTraveled*100) / distances);
};

export const getEta = (data: objectKV) => {
    let temp = [...(data?.planviaje??[])].sort((a:objectKV,b:objectKV) => +(b.orden ?? 0) - +(a.orden ?? 0));
            let _time = 0;
            if(data.idproximopuestocontrol){
                temp.some((item:objectKV) => {
                    _time += item.duracion;
                    if(data.idproximopuestocontrol === item.idpuestocontrol) return true;
                });
                return((_time*60000) + new Date().getTime());
            } else {
                return(+data.fechahoradescarguesalida || null);
            }
};

export const useSysState = (idsRemoved?:Array<number>) => {
    const {data} = useQueryToSever({query:getSysEstados, fetchPolicy:"cache-only"});
    const [sys, setSys] = React.useState<Array<confMap>>();
    React.useEffect(() => {
        let _sys = data?.sysEstados?.data;
        if(_sys?.length){
            let temp = _sys.filter((item:objectKV) => {
                return !(idsRemoved ?? []).find((item_2:number) => item_2 === +item.id);
            }).map((item:objectKV) => {
                let {id,iconname:className,descripcion:description} = item;
                return {id,className,description};
            });
            setSys(temp);
        }
    }, [data]);

    const getSysState = React.useCallback(() => {
        return sys;
    },[sys]);

    return React.useMemo(() => ({sys,getSysState}),[sys,getSysState]);
};

export const SysStatusFilter = (props:objectKV) => {
    const { sys } = useSysState(props.idsRemoved);
    return <InputSelect {...props} data={sys?.map((item: confMap) => ({...item,id:item.id+""}))} confSelect={{pathEmit:"id", pathOption:"description"}}/>
};

// 0: {id: 1, iconname: "icon-transito", descripcion: "EN TRÁNSITO", __typename: "Sysestados"}
// 1: {id: 2, iconname: "icon-pause", descripcion: "PAUSADO", __typename: "Sysestados"}
// 2: {id: 3, iconname: "icon-cargue", descripcion: "CARGANDO", __typename: "Sysestados"}
// 3: {id: 4, iconname: "icon-cancel", descripcion: "CANCELADO", __typename: "Sysestados"}
// 4: {id: 5, iconname: "icon-slash", descripcion: "ANULADO", __typename: "Sysestados"}
// 5: {id: 6, iconname: "icon-pendiente", descripcion: "PENDIENTE", __typename: "Sysestados"}
// 6: {id: 8, iconname: "icon-descargue", descripcion: "DESCARGANDO", __typename: "Sysestados"}
// 7: {id: 7, iconname: "icon-llegada", descripcion: "TERMINADO", __typename: "Sysestados"}
// 8: {id: 10, iconname: "icon-Recogiendo", descripcion: "RECOGIENDO CONTENEDOR", __typename: "Sysestados"}
// 9: {id: 11, iconname: "icon-cntenedor-en-transito", descripcion: "CONTENEDOR EN TRÁNSITO",…}
// 10: {id: 13, iconname: "icon-cargue-terminado", descripcion: "CARGADA", __typename: "Sysestados"}
// 11: {id: 14, iconname: "icon-pendiente", descripcion: "PLANILLADO", __typename: "Sysestados"}
// 12: {id: 12, iconname: "icon-entregando↵", descripcion: "ENTREGANDO CONTENEDOR", __typename: "Sysestados"}
// 13: {id: 9, iconname: "icon-check", descripcion: "DESCARGADO", __typename: "Sysestados"}
// 14: {id: 15, iconname: "icon-check", descripcion: "DESCARGADA", __typename: "Sysestados"}

export const FactoryFilters = (props:factorFilter) => {

    if(props.confField.field === "origen" || props.confField.field === "destino") {
        return (<InputText style={{width:90}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}>
        </InputText>);
    }

    if(props.confField.field === "idsysestado"){
        return (<SysStatusFilter idsRemoved={[12,4,7,15]} style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "idsysestadorem"){
        return (<SysStatusFilter idsRemoved={[1,2,4,5,7,10,11,14,12,15]} style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "placa"){
        return (<InputText style={{width:70}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "documentocliente"){
        return (<InputText style={{width:70}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "numeroremesa"){
        return (<InputText style={{width:120}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "advance"){
        return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "fechahoracitadescargue"){
        return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }
    if(props.confField.field === "ETA"){
        return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "fechahoraultimoevento"){
        return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    return null;
};

export const FactoryFiltersHistory = (props:factorFilter) => {

    if(props.confField.field === "origen" || props.confField.field === "destino") {
        return (<InputText style={{width:90}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}>
        </InputText>);
    }

    if(props.confField.field === "idsysestado"){
        return (<SysStatusFilter idsRemoved={[1,2,3,5,6,8,9,10,11,12,13,14,15]} style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "idsysestadorem"){
        return (<SysStatusFilter idsRemoved={[1,2,4,5,7,10,11,14,12,15]} style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "placa"){
        return (<InputText style={{width:70}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "documentocliente"){
        return (<InputText style={{width:70}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "numeroremesa"){
        return (<InputText style={{width:120}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "fechahoracitadescargue"){
        return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    if(props.confField.field === "fechahoraultimoevento"){
        return (<InputText style={{width:100}} name={props.confField.field} {...props.bind} disabled={!props.confField.filterable}/>);
    }

    return null;
};

const SysStatus = (props:{data:objectKV,istravel?:boolean}) => {
    
    const {data} = useQueryToSever({query:getSysEstados, fetchPolicy:"cache-only"});
    const [sys, setSys] = React.useState<confMap>(); 

    React.useEffect(() => {
        let _sys = data?.sysEstados?.data;
        if(_sys?.length){
            let temp = _sys.map((item:objectKV) => {
                let {id,iconname:className,descripcion:description} = item;
                return {id,className,description};
            }).find((item:confMap) => (props.istravel?props.data?.idsysestado:props.data?.idsysestadorem) === item.id)
            setSys(temp);
        }
    }, [data]);
    
    return <Icon className={`icon-status ${sys?.className}`} message={sys?.description ?? ""}/>
}

const Place = (props:{field: confTable,data:objectKV}) => {
    
    // let population = ""; 
    
    let value:string | undefined = undefined;
    
    // if(props.field?.multiPath) value = props.field?.multiPath.map((item: string | Array <string>) => destructor(item,props.data) );
    
    if(props.field?.path) value = destructor(props.field.path,props.data);
    // population = `${value[0]} (${value[1]})`;
    
    return (value !== undefined ?<div className="text-place"><TextView text={value} /></div>  : null);
}

export const Advanced = (props:{field: confTable,data:objectKV})  => {

    const [percentage, setPercentage] = React.useState<number>(0);

    React.useEffect(() => {
        if(props.data?.planviaje?.length){
            setPercentage(getPercentage(props.data));
        }
    }, [props.data]);

return <div className="indicator-traveled">
        <div className="indicator-traveled__fill" style={{width:`${percentage}%`}}>
        {`${percentage}%`}
        </div>
    </div>;
};

export const Eta = (props:{field: confTable,data:objectKV}) => {

    const [time, setTime] = React.useState<number>();

    React.useEffect(() => {
        if (props.data?.planviaje?.length) {
            let eta = getEta(props.data);
            if (eta) setTime(eta);
            else setTime(undefined);
        }
    }, [props.data]);

return <DateViwer data={{value:time}} path="value"/>;
};

export const Picture = (props:{ field: confTable, data:objectKV }) => {
    let [ numPicture, setNumPicture ] = React.useState<string>();
    let [ visible, setVisible ] = React.useState<boolean>(false);
    let [ images, setImages ] = React.useState<Array<{archivo:string}>>();
    const {state:storeImages,setData,getData} = useGeneralStore();

    const [loadData, { data, called,loading }] = useLazyQueryToSever({query:getSignedImage,fetchPolicy:"cache-first"});
    
    /**
     * esto es para pruebas visuales 
     * ____________________________________________________
     */
    // let [ numPictureT, setNumPictureT ] = React.useState<number>(0);
    // React.useEffect(() => {
    //     if(numPictureT) {
    //         let _numPicture= numPictureT;
    //         setNumPicture(_numPicture > 9?"9+":_numPicture+"");
    //     }
    //     else setNumPicture("");
    // }, [numPictureT]);


    // React.useEffect(() => {
    //     let interval = setInterval(() => {
    //         setNumPictureT(numPictureT => numPictureT + 1);
    //     },1000);
    //     return () => {
    //         clearInterval(interval);
    //     }
    // },[]);

    /**
     * _____________________________________________
     */

    React.useEffect(() => {
        if(props?.data?.numberdocs) {
            let _numPicture= props?.data?.numberdocs;
            setNumPicture(_numPicture > 9?"9+":_numPicture+"");
        }
        else setNumPicture("");
    }, [props?.data?.numberdocs]);    
    
    React.useEffect(() => {
        if(!data?.getSignedURLReport?.length) return setImages( []);
        Promise.all(data?.getSignedURLReport?.map(async (item:any) => {
            let uri = await getImage(item?.url);
            if(uri)setData({type:"SET_DATA",value:{key:item.key,value:uri}});
            else uri = getData(item.key);
            return {miniatura:uri,key:item.key,archivo:uri};
        }))
        .then((res:any)=> {
            setImages([...res]);
        })
    }, [data]);

    const open = () => {
        let _id = +(props?.data?.id ?? 0)
        if(!called && _id)loadData({variables:{id:_id}});
        setVisible(true);
    };

    const close = () => {
        setVisible(false);
    };
    
return numPicture? <div>
    <Modal visible={visible} clickOutside={close} noCenter backGroundColor="#00000061">
        <GalleryAbsolute images={images} loading={loading}/>
    </Modal>
    <i className="icon-fotos-cargadas icon-fotos-cargadas-cl" onClick={open} />
    </div>: null;
};

export const Factory = (props:{field: confTable,data:objectKV}) => {

    if(props.field.field === "origen" || props.field.field === "destino") return <Place {...props} />;
    
    if(props.field.field === "idsysestado") return <SysStatus data={props.data} istravel/>;
    if(props.field.field === "idsysestadorem") return <SysStatus data={props.data} />;

    if(props.field.field === "advance") return <Advanced {...props}/>;

    if(props.field.field === "ETA") return <Eta {...props}/>;

    if(props.field.field === "fotos") return <Picture {...props}/>;

    return null;
};
