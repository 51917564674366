import React from "react";
import { error } from "../../../utils/interface";
import "./Input.scss";
import Icon from "../Icon/Icon.tooltip";

interface InputCheckProps {
    /** indica si hay algun error */
    error?:error;
    /**
     * es el label que se mostrara en el input
     */
    label?:string;
    /**
     * se trata del mensaje que tendra el tooltip de esta casilla 
     */
    message?:string;
    /**
     * indica que la casilla esta desactivada  
     */
    disabled?:boolean;
    /** */
    [v:string]:any;
}
const InputCheck = React.memo((props: InputCheckProps) => {

    const {label,error, className,name, value, onChange} = props;

    /**Maneja el evento click  */
    const _onClick = () => {
        if(props.disabled) return;
        if(onChange) {
            onChange({target:{name,value:!value}});
        }
    };

    return (
        <div className="container">
            {label?<label className={`text-conf `}>{label}</label>:null}
                <div className={`input-check-container ${className}`}>
                    <div onClick={_onClick} className="input-check" style={{backgroundColor: props.disabled?"#8080804d":value?"#4d91fe":"white"}}>
                        {!props.disabled?<Icon className="icon-check" message={props.message}/>:null}
                    </div>
                </div>
            {error?.error?<label className="text-conf error">{error.message}</label>:null}
        </div>
    );
});

export default InputCheck;
