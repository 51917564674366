import React from "react";
import SideBar from "./../Componets/General/SideBar/SideBar";
import { useLazyQueryToSever } from "../Componets/hook/query-hook";
import { getSysEvents, getSysState, queryUtils as GET_UTILS } from "./../utils/utils";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useRouteMatch,
  } from "react-router-dom";
import Login from "./Login/Login";
// import AddController from "./../src-old/pages/AddController/AddController";
import "./Navigation.scss";
import NotFound from "./../Componets/Basic/NotFound/NotFound";

//desabilitar para producion 
// import PlayGround from "./PlayGround/PayGround";

import { UserProvider } from "./../Componets/Helpers/Session/user-context";
import { objectKV } from "../utils/interface";
import { getStoreInfo, getStoreInfoUtils, setStoreInfoUtils } from "../utils/store";
import { ResetPass } from "./Login/ResetPass";
import { Home } from "./Home/Home";
import { HistoryView } from "./History/History";
import { SideBarProvider } from "../Componets/Helpers/SideBar/side-bar-context";
import { useClientApolloGranada } from "../Componets/hook/client-apollo-hook";
import { NotificationContainer } from "../Componets/General/Notification/Notification";
import { NotificationProvider } from "../Componets/Helpers/Notification/notification-context";
import { GalleryView } from "./Gallery/Gallery";

const ViewsSideBar = () => {

    
    let { path, url } = useRouteMatch();
    
    let [loadData, { called, loading, data, refetch,fetchMore }] = useLazyQueryToSever({query:GET_UTILS});
    
    const apolloClient = useClientApolloGranada();

    //este es un contador que se altera cada minuto sirve para los botones y todos los contadores internos
    // NOTA si lo va a alterar debe tener mucho cuidado, pues puede afectar el socket los bootones de reporttes, los contadores de retrazo
    React.useEffect(() => {
        let interval = setInterval(() => {
            apolloClient.writeQuery({query:`
            query counter{
                timeCounter    
            }`, data: { timeCounter: new Date().getTime() } });
        },60000);
        getUtils();
        return () => {
            clearInterval(interval);
        }
    }, []);

    React.useEffect(() => {
        const { sysEstados, sysEventos } = data ?? {};
        if(sysEstados || sysEventos){
            const tempData = {
                sysEstados:{
                    data:sysEstados
                },
                sysEventos:{
                    data:sysEventos
                }
            };
            setStoreInfoUtils(tempData);
        }
    }, [data]);

    const getUtils = () => {
        const { sysEstados, sysEventos } = getStoreInfoUtils() ?? {};
        if(!sysEstados?.data || !sysEventos?.data) loadData();
        else {
            apolloClient.writeQuery({query:getSysEvents,data:{sysEventos:sysEventos?.data}});
            apolloClient.writeQuery({query:getSysState,data:{sysEstados:sysEstados?.data}});
        }
    };

    return (
        <SideBarProvider>
                <div className="views-home">
                    <div className="views-home__sidebar">
                        <SideBar/>
                    </div>
                    <div className="views-home__navigation">
                        <Switch>
                            <ProtectedRoute exact path={url}>
                                <Home/>
                            </ProtectedRoute>
                            <ProtectedRoute path={`${url}/history`}>
                                <HistoryView/>
                            </ProtectedRoute>
                            <ProtectedRoute path={`${url}/Gallery/:id`}>
                                <GalleryView/>
                            </ProtectedRoute>
                            <Route path={`${url}/network-error`} >
                                <NotFound />
                            </Route>
                            <Route  >
                                <NotFound />
                            </Route>
                        </Switch>
                    </div>
                </div>
        </SideBarProvider>
    );
}

const ProtectedRoute = (props:{children: JSX.Element } | objectKV) => {
    let {children, ...all} = props;
    return ( 
    // <Route {...all} render={() => children } />
    //NOTA: use esta funcio devido a que el hook es async, en este caso es mejor que sea sync 
    <Route {...all} render={() => getStoreInfo()?.token? children :<Redirect to="/Login" />} />
    );
}
const Navigation = () => {

    return (
        <React.Fragment>
            <NotificationContainer />
            <Switch>
                <ProtectedRoute path="/Home"> 
                    <ViewsSideBar /> 
                </ProtectedRoute>
    
                <Route exact path="/">
                    <Redirect to="/Home" />
                </Route>
    
                <Route exact path="/Login" >
                    <Login />
                </Route>

                <ProtectedRoute exact path="/resetPass" >
                    <ResetPass />
                </ProtectedRoute>

                <Route  >
                    <NotFound />
                </Route>
            </Switch>
        </React.Fragment>
        );
}

const NavigationAdapter = () => {
    return (
        <Router>
            <UserProvider>
                <NotificationProvider>
                    <Navigation />
                </NotificationProvider>
            </UserProvider>
        </Router>
    )
};

export default NavigationAdapter;