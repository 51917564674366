
export const queryDocuments = `
query getTravel($id:Int!){
    viajeClienteInfo(id:$id){
        id
        trazabilidad {
            id
            puestocontrol
            sysevento
            evento
            numberdocs
            fechahora
          }
    }
}
`;


export const queryUrlReport = `
query getSignedURLReport($idreporte:Int){
    getSignedURLReport(idreporte:$idreporte){
        id
        key
        url
    }
}
`;