import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found__code">404</div>
            <div className="not-found__info">A ocurrido un problema en la conexión espere un momento <Link to="/">ir a pagian principal </Link></div>
        </div>
    );
}

export default NotFound;