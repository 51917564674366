import React from "react";

const usePortal = (id:string) => {
    const el = React.useRef(document.createElement("div"));

    React.useEffect(() => {
    let selectElement = document.getElementById(id); 
    selectElement?.appendChild(el.current);
    return () => {
        el.current.remove();
    }

    }, []);

    return el.current;
};


export default usePortal;
