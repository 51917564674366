import React from  "react";
import ReactDOM from "react-dom";
import usePortal from "./../../hook/portal-hook";
import "./ToolTip.scss";

interface propsToolTip {
    /**
    * Es el componente que renderizara el tooltip
    */
    children: JSX.Element[] | JSX.Element;
    /**
     * representa la pocision del icono en y
     * 0 por defecto
     */
    top:number
    /**
     * representa la pocision del icono en x
     * 0 por defecto
     */
    left:number;

    /**
     * Indica si es visible o no el tooltip
     */
    visible:boolean;
}

const ToolTip = (props:propsToolTip) => {
    
    const el = usePortal("tooltip-root");

    return (props.visible ? ReactDOM.createPortal(<div className="tooltip" style={{top:props.top+20,left:props.left}} >
        {props.children}
    </div>,el):null)
};

export default ToolTip;