import React from "react";
import "./TextView.scss";
import ToolTip from "./../ToolTip/ToolTip";
import Button from "../Button/Button";
import Icon from "../Icon/Icon.tooltip";
import { useNotification } from "../../hook/notification-hook";
import { NotificationSucces } from "../../General/FormsUtils/Notification.fom";
import { objectKV } from "../../../utils/interface";

interface propsTextView {
    text?: string | number;
    /**
     * indica la etiqueta de 
     */
    label?: string;

    /**
     * es el ancho del campo en caso de no estar se ajustara al padre
     */
    width?:number;

    /**
     * es el largo del campo en caso de no estar se ajustara al padre
     */
    height?:number;

    children?:JSX.Element[] | JSX.Element;

    /**
     * Determina si se muestra el boton de copy
     */
    showCopy?:boolean;

    /**Determina el numero de lines que se presentaran en pantalla  */
    numLines?:number;

    /**
     * Son estilos unicamente para el texto
     */
     styleText?:objectKV;

}


/**
 * @description esta funcion se encarga de crear un componete de visualizacion para texto,
 * en caso de que el texto supere el tamaño del compoenente este lanzara un tooltip con el contenido del texto
 * @param props 
 */

const TextView = React.memo((props:propsTextView) => {
    
    /** Referencial al elemento contenedor del texto */
    const el = React.useRef(null);

    const {addNotification} = useNotification();

    /**Posicion del tooltip verticalmente */
    const [top, setTop] = React.useState<number>(0);

    /**Posicion del tooltip horizontalmente */
    const [left, setLeft] = React.useState<number>(0);

    /**Este valor es true cuando es necesario el tooltip */
    let [ itsOverFlow, setOverFlow] = React.useState<boolean>(false);

    /**Este valor es true cuando el tooltip es visible*/
    const [visible, setVisible] = React.useState<boolean>(false);

    /**Dispara la funcio cada vez que ocurre un cambio en la referencia al elemento */
    React.useEffect(() => {
        let _el:any = el?.current;
        if(props.numLines) return setOverFlow(_el?.offsetHeight < _el.scrollHeight);
        return setOverFlow(_el?.offsetWidth < _el?.scrollWidth);
    }, [props.text]);

    /**  */
    const _onMouseMoveCapture = (e:any) => {
        if(props.children) return;//si existe un hijo no funciona el tooltip
        if(!itsOverFlow) return;
        if(e){
            let _top = 0,_left = 0;
            if(+e.clientY) {
                _top = e.clientY;
                // este elemento cambia cuando se esta cerca de la posicion final de la pagina
                // para permitir renderizar el tool tip sobre el mouse 
                if((window.innerHeight - e.clientY) < 50) _top = e.clientY - 50;
            }
            if(+e.clientX) {
                _left = +e.clientX;
            }
            setTop(_top);
            setLeft(_left);
        }
        if(!visible) setVisible(true);
    };

    const _onMouseLeave = () => {
        if(!itsOverFlow) return;
        setVisible(false);
        setTop(0);
        setLeft(0);
    };

    const copy = React.useCallback(() => {
        let _text = props.text+"";
        if(!_text) return;
        const tempEl = document.createElement('textarea');
        tempEl.value = _text;
        document.body.appendChild(tempEl);
        tempEl.select();
        document.execCommand('copy');
        if(addNotification) {
            addNotification({element:<NotificationSucces message={`Texto copiado`} />,delay:800});
        }
        document.body.removeChild(tempEl);
    },[props.text]);

    return (
        <div className="text-view" 
        style={{  width: props.width ?? "",height: props.height ?? ""}} 
        >
            {props.label? <div
            onMouseMoveCapture={_onMouseMoveCapture}
            onMouseLeave={_onMouseLeave}
            className="text-view__label">{props.label}</div>:null}
            {itsOverFlow?  <ToolTip visible={visible} top={top} left={left} >
                <label className="text-tooltip">{props.text} </label>
            </ToolTip>:null}
            <div
            className="text-view__ct" >
                {
                    props.text && props.showCopy?
                    <Button message="copy" onClick={copy} className="button-copy">
                        <Icon className="icon-copy"/>
                    </Button>
                    :null
                }
                <div 
                ref={el} 
                onMouseMoveCapture={_onMouseMoveCapture}
                onMouseLeave={_onMouseLeave}
                className={`text-view__ct__text ${props.numLines?"text-view__ct__text--multi-lines":""}`}
                style={{WebkitLineClamp:props.numLines,...(props.styleText??{})}}
                >
                    {props.children ?? props.text ?? ""}
                </div>
            </div>
        </div>
    );
})

export default TextView;