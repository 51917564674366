import React from "react";
import "./Layout.scss"
import { action } from "../../../utils/interface";
import { OpenSideBar,OptionsButton } from "./Layout";


interface propsLayout {
    /**
     * 
     */
    children?: JSX.Element[] | JSX.Element;

    /**
     * este es el titulo del layout
     */
    title?:string|JSX.Element;

    /**
     * este es el componente que se renderizara en el centro del div
     */
    center?: JSX.Element[] | JSX.Element;

    /**
     * este es el componente que se renderizara en el lado derech del div
     */
    right?: JSX.Element[] | JSX.Element;

    /**
     * este es el componente que se renderiza en la parte inferior derecha de la vista
     */
    optionMore?: JSX.Element[] | JSX.Element;

    /**
     * Es el numero de registros que se mostraran por defecto es 20
     */
    numReg?:number;

    /**
     * Es el total actual de registros
     */
    total?:number;
    /**
     * Es el total actual de registros
     */
    desabledNumReg?:boolean;
    /**
     * Es la pagina actual lo normal es que sea un numero mayor a 0.
     */
    pageCurrent?:number;

    /**
     * Es el total actual de registros
     */
    goToPage?:(v:action) => any;

    /**Este Componente se va a ver en la izquierda baja del layout*/
    leftBottom?: JSX.Element[] | JSX.Element;

}

export const LayoutWithOutPage = React.memo((props:propsLayout) => {

    return (
        <div className="layout">
            <div className="layout__header">
                <div className="layout__header__title">
                    <OpenSideBar />
                    <div className="button-options-header text-options">
                        <OptionsButton />
                    </div>
                </div>
                <div className="layout__header__options">
                    <div className="layout__header__options__clm">
                        <div className="text-titles">
                            {props.title ?? ""}
                        </div>
                    </div>
                    <div className="layout__header__options__center">
                        {props.center}
                    </div>
                    <div className="layout__header__options__clm">
                        {props.right}
                    </div>
                </div>
            </div>
            <div className="layout__body">
                {props.children}
            </div>
            <div className="layout__option-buttons-more">
                {props.optionMore}
            </div>
            <div className="layout__footer">
               
            </div>
        </div>
    )
});
