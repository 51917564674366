import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client/core";
import { getStoreInfo, delStoreInfo, delStoreInfoUtils } from "./../utils/store";
import { onError } from "@apollo/client/link/error";

const linkHttp = new HttpLink({uri:`${process.env.REACT_APP_URL}/graphql`});
/**
 * @description es un afterware, se ejecuta inmediatamente despues de que una peticion falla
 */
const linkError = onError(({ graphQLErrors, networkError }) => {

  if (graphQLErrors && graphQLErrors[0]?.extensions?.code === "UNAUTHENTICATED") {
    // se usa para recargar la ventana y borrra los datos de sesion.
    delStoreInfo();
    delStoreInfoUtils();
    window.location.reload();

  }
  else if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  else if (networkError) console.log(`[Network error]: ${networkError}`);
});

// cached storage for the user token
// const withToken = setContext(() => {

//   let headers = getStoreInfo();
  
//   return {headers:{ 
//       Authorization:headers.token,
//       idapp:process.env.REACT_APP_ID,
//       idempresa:headers.idcompany
//   }}
// });

/**
 * @description es un middleware se ejecuta antes durante la peticion, con el fin de agregar las variables de autenticacion.
 */
const authMiddleware = new ApolloLink((operation:any, forward:any) => {

  let coockie = getStoreInfo();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      Authorization:coockie.token,
      idapp:process.env.REACT_APP_ID,
      idempresa:coockie.idcompany
    }
  }));

  return forward(operation);
})

// /**
//  * @description crea un link para la subida de archivos
//  * @deprecated esta funcion debe ser retirada 
//  */
// const link = createUploadLink({uri:process.env.REACT_APP_URL+"/graphql"?.toString()});

/**
 * @description crea la memoria cache de apollo 
 */
const cache = new InMemoryCache({
  // dataIdFromObject: object => object.id
});

/**
 * @description crea un nuevo cliente de apollo.
 */
const client = new ApolloClient({
  // connectToDevTools:true,
  cache,
  link: from([ authMiddleware, linkError, linkHttp])
});

export default client;