import * as React from 'react';
import "./Click.away-listener.scss";

/**
 * @description esta funcion crea un componente, que reconoce el click fuera de si mismo
 * @version 1.0
 */
export const ClickAwayListener = React.memo((props:{
    children?: JSX.Element[] | JSX.Element;
    /**Es la funcion que se ejecuta en caso de haber click fuera de si */
    clickOutside?:(v?:any) => any;
}) => {

    const { children, clickOutside } = props;

    /**Estado para refernciar el contenedor */
    const el:any = React.useRef(null);

    /**Funcion que maneja el evento clickoutside */
    const _clickOutside = (e: any) => {
        //Se determina si el elemento en el que ocurrio el click esta contenido en este de no ser asi entonces se llama el clickOutside
        if (el?.current && !el?.current.contains(e?.target)) {
            if(clickOutside) clickOutside();
        }
    }; 

    React.useEffect(() => {
        //Se crea un listener para el click en el documento
        document.addEventListener('click', _clickOutside, true);
        /**Se remueve el click en el documento cundo el componente es desmontado */
        return () => document.removeEventListener('click', _clickOutside, true);
    },[]);

    return (
        <div ref={el} className="container-listener-away">
            {children}
        </div>
    );
});