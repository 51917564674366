import React from "react";
import imageLogoGranada from "./../../utils/img/icon/logo-granada.svg";
import InputFloat from "./../../Componets/Basic/Input/InputFloat";
import { useInput } from "./../../Componets/hook/input-hook";
import { error } from "./../../utils/interface";
import Button from "./../../Componets/Basic/Button/Button";
import LoadCircular from "./../../Componets/Basic/LoadIndicator/LoadCircular";
import { setStoreInfo, getStoreInfo } from "./../../utils/store";
import { Redirect, useHistory } from "react-router-dom";
import { useUser } from "./../../Componets/hook/user-hook";
import "./Login.scss";
import imageIcon from "./../../utils/img/icon/logo.png";


/**
 * @description esta funcion hace el login y lo guarda en una cookie 
 * @param user 
 * @param password 
 */
const _login = (user:string, password:string) => new Promise((resolve:any,reject:any) => {
    fetch(process?.env?.REACT_APP_URL+"/api/login".toString(), {
        method:"post", 
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
            "username": user,
            "password": password,
            "extra": {
                "code": +(process.env?.REACT_APP_ID ?? 0)
            }
        })
    })
    .then(async (res:any) => {
        let {data} = await res.json();
        return {data, code:res.status}
    })
    .then((res:any) => {
        let { data } = res;
        console.log(res)
        let values = {
            idcompany:data?.idempresa,
            token:data?.token,
            id:data?.usuario?.idusuario,
            name:data?.usuario?.name,
            roll:data?.usuario?.idrol,
            logo:data?.usuario?.urllogo,
            clientId:data?.usuario?.idcliente
        };

        //Se valida la existencia de un token, para poder enviarlo a las cookies  
        if(data.token) resolve({data:values, code:res.code});
        else reject(); // En caso de no existir el token, se termina la funcion 
        
    }).catch((err:any) => {
        reject();
    });
});

const ErrorView = (props:{error?:error}) => {
    return (props?.error?.error?<div className="erro-view-container-login">
        {props?.error?.message ?? "Error"}
    </div>:null);
}

const Login = () => {
    
    let {value:password, reset:resetPass, bind:bindPass} = useInput();
    let {value:user, reset:resetUser, bind:bindUser} = useInput();
    let {value:code, reset:resetCode, bind:bindCode} = useInput();
    let [error, setError] = React.useState<error>();
    let [errorCode, setErrorCode] = React.useState<error>();
    let [errorPass, setErrorPass] = React.useState<error>();
    let [errorUser, setErrorUser] = React.useState<error>();
    let [loading, setLoading] = React.useState<boolean>(false);

    let {login} = useUser();

    const history = useHistory();

    React.useEffect(() => {
        if(+(password?.length??0) > 0){
            //Validar tamaño de la password.
            setErrorPass({message:"Requerido", error:false});
        }
        if(+(user?.length??0) > 0){
            //Validar tamaño del user.
            setErrorUser({message:"Requerido", error:false});
        }
        if(+(code?.length??0) > 0){
            //Validar tamaño del user.
            setErrorCode({message:"Requerido", error:false});
        }
    }, [password,user,code]);

    const _onClick = () => {
        if(password?.length === 0){//se leva a cabo un serie de validaciones 
            setErrorPass({message:"Requerido", error:true});
        }
        if(user?.length === 0){//se leva a cabo un serie de validaciones
            setErrorUser({message:"Requerido", error:true});
        }
        if(code?.length === 0){//se leva a cabo un serie de validaciones
            setErrorCode({message:"Requerido", error:true});
        }
        if(!password || password?.length === 0 || user?.length === 0 || code?.length === 0) return;
        setLoading(true);
        //si todo esta bien entonces se llama el metodo de login.
        _login(`${user}-${code}`, password).then((res: any) => {
    console.log("v3",res.data)

            login(res.data);
            if(res?.code === 409) {
                history.push("/resetPass");
            }
        }).catch((err:any) => {
            setError({error:true, message:"No se pudo autenticar"});
            setTimeout(() =>  setError({error:false, message:"No se pudo autenticar"}),1500);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    // Usamos esta condicion para redirecionar al home si se esta loggedo 
    //NOTA: use esta funcio devido a que el hook es async, en este caso es mejor que sea sync 
    if(getStoreInfo()?.token) return <Redirect to="Home" />;

    return (
        <div className="login">
            <div className="login__image">
                <img src={imageIcon}  />
            </div>
            <div className="login__code">
                <InputFloat error={errorCode} {...bindCode} label="Codigo" />
            </div>
            <div className="login__user">
                <InputFloat error={errorUser} {...bindUser} label="Documento" />
            </div>
            <div className="login__password">
                <InputFloat error={errorPass} {...bindPass} label="Contraseña" type="password" />
            </div>
            <div className="login__button">
                <Button onClick={_onClick} disabled={loading} className="button-send">
                    {!loading? <label>Iniciar</label>:<LoadCircular />}
                </Button>
            </div>
            <div className="login__year">
                <img src={imageLogoGranada} className="logoGranada"/>
                {new Date().getFullYear()}
                &#169;
            </div>
            <ErrorView error={error} />
        </div>
    );
};

export default Login;